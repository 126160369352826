import Bulk from "pages/cs/Bulk";
import Certified from "pages/member/Certified";
import Find from "pages/member/FindPw";
import WelCome from "pages/member/WelCome";
import Addr from "pages/mypage/Addr";
import Alarm from "pages/mypage/Alarm";
import EmailEdit from "pages/mypage/EmailEdit";
import Inquiry from "pages/mypage/Inquiry";
import ProfileEdit from "pages/mypage/ProfileEdit";
import PwEdit from "pages/mypage/pwEdit";
import PublicOnly from "pages/private/publicOnly";
import BabyEdit from "pages/report/BabyEdit";
import CardDetail from "pages/report/CardDetail";
import ChemiDetail from "pages/report/ChemiDetail";
import InspectionStatus from "pages/report/InspectionStatus";
import ReportDetail from "pages/report/ReportDetail";
import SurveyCom from "pages/report/SurveyCom";
import OrderFailure from "pages/shop/OrderFailure";
import OrderSuccess from "pages/shop/OrderSuccess";
import React from "react";
import { Navigate } from "react-router-dom";


//로그인,비로그인 무관 페이지
export const publicRoutes = [
    {
        exact: true,
        path: "/cs/bulk",
        component: <Bulk/>,
    },
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
    },
    {
        exact: true,
        path: "/order/failure",
        component: <OrderFailure/>,
    },
    {
        exact: true,
        path: "/order/chkout/:id",
        component: <OrderSuccess/>,
    },
];
//비로그인 한정 페이지
export const publicRoutesOnly = [
    {
        exact: true,
        path: "/welCome",
        component: <WelCome/>,
    },
    {
        exact: true,
        path: "/member/find/:type",
        component: <Find/>,
    },
    {
        exact: true,
        path: "/member/certified/:type/:marketingType",
        component: <Certified/>,
    },
];
//로그인 한정 페이지
export const privateRoutes = [
    {
        exact: true,
        path: "/mypage/pwEdit",
        component: <PwEdit/>,
    },
    {
        exact: true,
        path: "/mypage/pwEdit",
        component: <PwEdit/>,
    },
    {
        exact: true,
        path: "/mypage/alarm",
        component: <Alarm/>,
    },
    {
        exact: true,
        path: "/mypage/emailEdit",
        component: <EmailEdit/>,
    },
    {
        exact: true,
        path: "/mypage/addr",
        component: <Addr/>,
    },
    {
        exact: true,
        path: "/mypage/profileEdit",
        component: <ProfileEdit/>,
    },
    {
        exact: true,
        path: "/report/family/baby/:id",
        component: <BabyEdit/>,
    },
    {
        exact: true,
        path: "/report/status/:id",
        component: <InspectionStatus/>,
    },
    {
        exact: true,
        path: "/survey/success",
        component: <SurveyCom/>,
    }
];