import { BtnBox, BtnItem, BtnItemLink } from "component/basic/btns";
import { useNavigate } from "react-router-dom";

//알림 항목
function AlarmItem(data){
    let navigate = useNavigate();

    return (
        <div className={`alarmItem ${data.addClass ? data.addClass : ""}`}>
            <div className="alarmItem_head">
                <div className="alarmItem_head_textBox">
                    <img src={`/assets/images/alarm/${data?.data?.type}.svg`} className="alarmItem_icon"/>
                    <h1 className="alarmItem_name">{data?.data?.title}</h1>
                </div>
                {data?.data?.is_new == 1 ? <img src="/assets/images/alarm/new.svg" className="alarmItem_newIcon"/> : ""}
            </div>
            <p className="alarmItem_contents" dangerouslySetInnerHTML={{__html:data?.data?.contents.replace(/\n/g,"<br/>")}}/>
            <BtnBox
                boxType=""
                addClass={data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 10 || data?.data?.status == 15 || data?.data?.status == 29 ? "col2 alarmBtnBox" : " alarmBtnBox"}
            >
                <BtnItem
                    addClass={data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 10 || data?.data?.status == 15 || data?.data?.status == 29 ? "line" : ""}
                    contents={
                        data?.data?.status == 1 || data?.data?.status == 2 || data?.data?.status == 3 || data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 8 || data?.data?.status == 9 || data?.data?.status == 10 || data?.data?.status == 11 || data?.data?.status == 12 || data?.data?.status == 13 || data?.data?.status == 14 || data?.data?.status == 30 ? 
                        "View Order Details"
                        : data?.data?.status == 4 ? 
                        "Received"
                        : data?.data?.status == 7 ? 
                        "Check Rejection Reasons"
                        : data?.data?.status == 15 ? 
                        "Enter Barcode"
                        : data?.data?.status == 16 ? 
                        "Conduct Survey"
                        : data?.data?.status == 17 || data?.data?.status == 24 ? 
                        "Apply for Return"
                        : data?.data?.status == 18 ? 
                        "Check Testing Status"
                        : data?.data?.status == 19 ? 
                        "View Results"
                        : data?.data?.status == 20 || data?.data?.status == 25 ? 
                        "Enter Reshipping Address"
                        : data?.data?.status == 21 || data?.data?.status == 22 ? 
                        "View Details"
                        : data?.data?.status == 23 ? 
                        "Re-enter Barcode"
                        : data?.data?.status == 26 || data?.data?.status == 27 ? 
                        "Confirm Partner"
                        : data?.data?.status == 28 ? 
                        "Re-register Partner"
                        : data?.data?.status == 29 ? 
                        "<i>Go to</i><i class='notranslate'> BabyDNA</i>"
                        : ""
                    }
                    disabled={false}
                    func={() => {
                        if(data?.data?.status == 1 || data?.data?.status == 2 || data?.data?.status == 3 || data?.data?.status == 4 || data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 8 || data?.data?.status == 9 || data?.data?.status == 10 || data?.data?.status == 11 || data?.data?.status == 12 || data?.data?.status == 13 || data?.data?.status == 14 || data?.data?.status == 30){
                            navigate(`/mypage/order/detail/1/${data?.data?.target_id}`);
                        }else if(data?.data?.status == 15){
                            navigate(`/addBarcode`);
                        }else if(data?.data?.status == 16){
                            navigate(`/survey/${data?.data?.target_id}/${data?.data?.target_id == data.id ? "me" : "baby"}/basic`);
                        }else if(data?.data?.status == 17 || data?.data?.status == 24){
                            navigate(`/return/${data?.data?.target_id}/normal/notice`);
                        }else if(data?.data?.status == 18 || data?.data?.status == 21){
                            navigate(`/report/status/${data?.data?.target_id}`);
                        }else if(data?.data?.status == 19){
                            navigate(`/report`);
                        }else if(data?.data?.status == 20 || data?.data?.status == 22 || data?.data?.status == 23 || data?.data?.status == 25 || data?.data?.status == 29){
                            navigate(`/main`,{state: {selUserId: data?.data?.target_id}});
                        }else if(data?.data?.status == 26 || data?.data?.status == 27 || data?.data?.status == 28){
                            navigate(`/report/family`);
                        }
                    }}
                />
                {data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 10 || data?.data?.status == 29 ? 
                    <BtnItem
                        addClass=""
                        contents={
                            data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 10 ? 
                            "1:1 Inquiry"
                            : data?.data?.status == 29 ? 
                            "Adjust Pickup Schedule"
                            : ""
                        }
                        disabled={false}
                        func={() => {
                            if(data?.data?.status == 5 || data?.data?.status == 6 || data?.data?.status == 7 || data?.data?.status == 10){
                                navigate(`/mypage/order/detail/1/${data?.data?.target_id}`,{state: {zendesk: true}})
                            }else if(data?.data?.status == 29){
                                let aTag = document.createElement("a");
                                document.body.appendChild(aTag);
                                aTag.href = `tel:+62 813-8855-2210`;
                                aTag.click();
                                document.body.removeChild(aTag);
                            }
                        }}
                    />
                :""}
                {data?.data?.status == 15 ?
                    <BtnItemLink
                        contents="View Analysis Method"
                        href=""// alert("미비사항(분석방법보기 링크 미수신)");
                    />
                :""}
            </BtnBox>
        </div>
    );
}

export {AlarmItem};