import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { publicRoutes, privateRoutes, publicRoutesOnly } from "./routes";
import ScrollToTop from "component/basic/scrollTopSet";
import Login from "pages/member/Login";
import Join from "pages/member/Join";
import Setting from "pages/mypage/Setting";
import { LoadingBox, Popup, RightPopup, ToastPopup } from "component/basic/popup";
import Withdrawal from "pages/mypage/Withdrawal";
import Main from "pages/main/Main";
import AddSpouse from "pages/report/AddSpouse";
import FamilyManag from "pages/report/FamilyManag";
import Order from "pages/shop/Order";
import useGet from "api/useGet";
import Terms from "pages/member/Terms";
import AddBarcode from "pages/main/AddBarcode";
import { SpouseAddInfoArea } from "component/report/items";
import { useRef } from "react";
import { CookieContents, CookieTitle } from "component/app/cookie";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { BtnBox, BtnItem } from "component/basic/btns";
import Collaboration from "pages/cs/Collaboration";
import Chemi from "pages/report/Chemi";
import Report from "pages/report/Report";
import ShopList from "pages/shop/List";
import Cart from "pages/shop/Cart";
import Social from "pages/member/Social";
import { ErrorBoundary } from "react-error-boundary";
import SpousePopup from "component/app/spousePopup";
import KeySetting from "component/app/keySetting";
import Faq from "pages/cs/Faq";
import ShopDetail from "pages/shop/Detail";
import OrderCom from "pages/shop/OrderCom";
import OrderList from "pages/mypage/Order";
import OrderReturn from "pages/mypage/Return";
import OrderCs from "pages/mypage/OrderCs";
import OrderDetail from "pages/mypage/OrderDetail";
import Return from "pages/main/Return";
import ReportDetail from "pages/report/ReportDetail";
import ChemiDetail from "pages/report/ChemiDetail";
import Inquiry from "pages/mypage/Inquiry";
import LanguageReSet from "component/app/language";
import CardDetail from "pages/report/CardDetail";
import Survey from "pages/report/Survey";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [termsId,setTermsId] = useState("");
    const [termsTitle,setTermsTitle] = useState("");

    const [languageType,setLanguageType] = useState(localStorage.getItem("language")||"en");
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    //로그인,로그아웃
    const logIn = () => {setToken(true)};
    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        sessionStorage.removeItem("chemiId");
        sessionStorage.removeItem("reportId");
        sessionStorage.removeItem("reportName");
        sessionStorage.removeItem("mainUserData");

        setToken(false);
    };

    window.errLogout = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        
        sessionStorage.removeItem("chemiId");
        sessionStorage.removeItem("reportId");
        sessionStorage.removeItem("reportName");
        sessionStorage.removeItem("mainUserData");

        setToken(false);

        window.location.reload(true);
    }

    const [cookiePopupData,setCookiePopupData] =  useState(null);
    const [cookieSelData,setCookieSelData] =  useState({
        cookieAccess:localStorage.getItem("cookieAccess")||false,
        funcCookieAccess:localStorage.getItem("funcCookieAccess")||false,
        performanceCookieAccess:localStorage.getItem("performanceCookieAccess")||false,
        marketingCookieAccess:localStorage.getItem("marketingCookieAccess")||false
    });
    const [cookieRightOpenType,setCookieRightOpenType] =  useState(false);
    const [slideIndex,setSlideIndex] = useState(0);

    //쿠키 약관 정보
    const cookieApi = useGet({
        url: cookieSelData.cookieAccess || token ? null : `/term?term_id=${5}`
    });
    const cookieData = cookieSelData.cookieAccess || token ? "" : cookieApi?.data?.term?.term_contents;

    const funcCookieApi = useGet({
        url: cookieSelData.cookieAccess || token ? null : `/term?term_id=${12}`
    });
    const funcCookieData = cookieSelData.cookieAccess || token ? "" : funcCookieApi?.data?.term?.term_contents;

    const performanceCookieApi = useGet({
        url: cookieSelData.cookieAccess || token ? null : `/term?term_id=${13}`
    });
    const performanceCookieData = cookieSelData.cookieAccess || token ? "" : performanceCookieApi?.data?.term?.term_contents;

    const marketingCookieApi = useGet({
        url: cookieSelData.cookieAccess || token ? null : `/term?term_id=${14}`
    });
    const marketingCookieData = cookieSelData.cookieAccess || token ? "" : marketingCookieApi?.data?.term?.term_contents;

    const [terms,setTerms] =  useState("");
    const [rightOpenType,setRightOpenType] =  useState(false);

    const termsApi = useGet({
        url:`/term?term_id=${termsId}`
    });
    const termsData = termsApi?.data?.term;

    //팝업 오픈
    const rightOpen = (id,title) => {
        setTermsId(id)
        setTermsTitle(title)
    }

    useEffect(()=>{
        if(termsData){
            setTerms({
                title:termsTitle,
                text:termsData?.term_contents
            })
            setRightOpenType(true)
        }
    },[termsData]);

    //쿠키 로컬 세팅
    function cookieAccess(type){
        localStorage.setItem("cookieAccess",true);
        if(type == "all"){
            localStorage.setItem("funcCookieAccess",true);
            localStorage.setItem("performanceCookieAccess",true);
            localStorage.setItem("marketingCookieAccess",true);
        }else if(type == "sel"){
            localStorage.setItem("funcCookieAccess",cookieSelData?.funcCookieAccess);
            localStorage.setItem("performanceCookieAccess",cookieSelData?.performanceCookieAccess);
            localStorage.setItem("marketingCookieAccess",cookieSelData?.marketingCookieAccess);
        }
    }

    useEffect(()=>{
        if(!cookieSelData.cookieAccess && !token){
            setCookiePopupData({
                addClass:null,
                title:"Allow Cookies",
                closeType:false,
                headBtn:"Cookie Settings",
                headBtnFunc:(e) => {
                    setCookieRightOpenType(true);
                },
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    cookieAccess("normal");
                },
                btn0:"Allow only essential cookies",
                btn0Type:"line",
                btn1:"Allow all cookies",
                btn1Type:"",
                btnFunc1:()=>{
                    cookieAccess("all");
                }
            });
        }
    },[]);

    //언어 세팅
    useEffect(()=>{
        localStorage.setItem("language",languageType);
        setLoadingBoxOpen(true);
        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 2000);
        setTimeout(() => {
            if(window?.google?.translate?.TranslateElement){
                new window.google.translate.TranslateElement({
                    pageLanguage: 'en',
                    includedLanguages: 'id',
                    autoDisplay: false
                },"google_translate_element");

                const gtcombo = document.querySelector(".goog-te-combo");
                if (gtcombo == null) {
                    return false;
                }else{
                    if(languageType == "en"){
                        var iframe = document.getElementsByClassName('VIpgJd-ZVi9od-ORHb-OEVmcd')[0];
                        if(!iframe) return;

                        var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                        var restore_el = innerDoc.getElementsByTagName("button");
                        if(restore_el){
                            for(var i = 0; i < restore_el.length; i++){
                                if(restore_el[i].id.indexOf("restore") >= 0) {
                                    restore_el[i].click();
                                    return;
                                }
                            }
                        }
                    }else{
                        gtcombo.value = languageType;
                        gtcombo.dispatchEvent(new window.Event("change"));
                    }
                }
                setTimeout(() => {
                    setLoadingBoxOpen(false);
                }, 500);
            }else{
                setTimeout(() => {
                    new window.google.translate.TranslateElement({
                        pageLanguage: 'en',
                        includedLanguages: 'id',
                        autoDisplay: false
                    },"google_translate_element");
        
                    const gtcombo = document.querySelector(".goog-te-combo");
                    if (gtcombo == null) {
                        return false;
                    }else{
                        if(languageType == "en"){
                            var iframe = document.getElementsByClassName('VIpgJd-ZVi9od-ORHb-OEVmcd')[0];
                            if(!iframe) return;
    
                            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                            var restore_el = innerDoc.getElementsByTagName("button");
                            if(restore_el){
                                for(var i = 0; i < restore_el.length; i++){
                                    if(restore_el[i].id.indexOf("restore") >= 0) {
                                        restore_el[i].click();
                                        return;
                                    }
                                }
                            }
                        }else{
                            gtcombo.value = languageType;
                            gtcombo.dispatchEvent(new window.Event("change"));
                        }
                    }
                    setTimeout(() => {
                        setLoadingBoxOpen(false);
                    }, 500);
                }, 1000);
            }
        }, 500);
    },[languageType]);

    window.languageSetting = function(){
        setTimeout(() => {
            const gtcombo = document.querySelector(".goog-te-combo");
            if (gtcombo == null) {
                return false;
            }else{
                if(languageType == "en"){
                    var iframe = document.getElementsByClassName('VIpgJd-ZVi9od-ORHb-OEVmcd')[0];
                    if(!iframe) return;

                    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                    var restore_el = innerDoc.getElementsByTagName("button");
                    if(restore_el){
                        for(var i = 0; i < restore_el.length; i++){
                            if(restore_el[i].id.indexOf("restore") >= 0) {
                                restore_el[i].click();
                                return;
                            }
                        }
                    }
                }else{
                    gtcombo.value = languageType;
                    gtcombo.dispatchEvent(new window.Event("change"));
                }
            }
        }, 1500);
    }

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <img src="/assets/images/icon/pageErr.svg"/>
                    <h1 className="pageErrTitle"><i>We apologize for</i><br/><i>any inconvenience with</i><i className="notranslate"> BabyDNA </i><i>usage.</i></h1>
                    <p className="pageErrText">The page you are looking for does not exist, or an error has occurred.</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.reload(true)}>Refresh</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div id="google_translate_element"></div>
        <BrowserRouter>
            <ScrollToTop/>
            <KeySetting token={token}/>
            <Routes>
                {/* 기본접근 */}
                {publicRoutes.map(
                    ({ exact, path, component }) => (
                        <Route
                            key={`public-route-${path}`}
                            exact={exact}
                            path={path}
                            element={component}
                        />
                    )
                )}

                {/* 비로그인만 접근 */}
                {publicRoutesOnly.map(
                    ({ exact, path, component }) => (
                        <Route
                            key={`public-route-${path}`}
                            exact={exact}
                            path={path}
                            element={!token ? component : <PublicOnly/>}
                        />
                    )
                )}

                {/* 로그인만 접근 */}
                {privateRoutes.map(
                    ({ exact, path, component }) => (
                        <Route
                            key={`public-route-${path}`}
                            exact={exact}
                            path={path}
                            element={token ? component : <Private/>}
                        />
                    )
                )}

                {/* 로그인 체크 */}
                <Route
                    key={`public-route-/member/login/:type`}
                    exact={true}
                    path="/member/login/:type"
                    element={!token ? <Login logIn={logIn}/> : <PublicOnly/>}
                />
                <Route
                    key={`public-route-/member/join/:type/:marketingType`}
                    exact={true}
                    path="/member/join/:type/:marketingType"
                    element={!token ? <Join logIn={logIn}/> : <PublicOnly/>}
                />
                <Route
                    key={`public-route-/social/:type`}
                    exact={true}
                    path="/social/:type"
                    element={!token ? <Social logIn={logIn}/> : <PublicOnly/>}
                />

                {/* 로그아웃 체크 */}
                <Route
                    key={`public-route-/mypage/setting`}
                    exact={true}
                    path="/mypage/setting"
                    element={token ? <Setting rightOpenFunc={(id,title)=>rightOpen(id,title)} logOut={logOut}/> : <Private/>}
                />
                <Route
                    key={`public-route-/mypage/withdrawal`}
                    exact={true}
                    path="/mypage/withdrawal"
                    element={token ? <Withdrawal logOut={logOut}/> : <Private/>}
                />

                {/*팝업*/}
                <Route
                    key={`public-route-/`}
                    exact={true}
                    path="/"
                    element={<Main rightOpenFunc={(id,title)=>rightOpen(id,title)} language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/main`}
                    exact={true}
                    path="/main"
                    element={<Main rightOpenFunc={(id,title)=>rightOpen(id,title)} language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/report/chemi`}
                    exact={true}
                    path="/report/chemi"
                    element={<Chemi language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/report/chemi/detail/:id/:category`}
                    exact={true}
                    path="/report/chemi/detail/:id/:category"
                    element={token ? <ChemiDetail language={languageType}/> : <Private/>}
                />
                <Route
                    key={`public-route-/report`}
                    exact={true}
                    path="/report"
                    element={<Report language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/shop`}
                    exact={true}
                    path="/shop"
                    element={<ShopList language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/shop/detail/:id/:type`}
                    exact={true}
                    path="/shop/detail/:id/:type"
                    element={<ShopDetail language={languageType}/>}
                />
                <Route
                    key={`public-route-/cart`}
                    exact={true}
                    path="/cart"
                    element={<Cart language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                />
                <Route
                    key={`public-route-/order/:type`}
                    exact={true}
                    path="/order/:type"
                    element={<Order language={languageType} rightOpenFunc={(id,title)=>rightOpen(id,title)}/>}
                />
                <Route
                    key={`public-route-/order/success/:id`}
                    exact={true}
                    path="/order/success/:id"
                    element={<OrderCom language={languageType}/>}
                />
                <Route
                    key={`public-route-/mypage/order`}
                    exact={true}
                    path="/mypage/order"
                    element={token ? <OrderList language={languageType}/> : <Private/>}
                />
                <Route
                    key={`public-route-/mypage/order/detail/:type/:id`}
                    exact={true}
                    path="/mypage/order/detail/:type/:id"
                    element={<OrderDetail language={languageType}/>}
                />
                <Route
                    key={`public-route-/mypage/order/cs/:type/:id/:productId`}
                    exact={true}
                    path="/mypage/order/cs/:type/:id/:productId"
                    element={<OrderCs language={languageType}/>}
                />
                <Route
                    key={`public-route-/mypage/order/return/:type/:status/:id/:productId`}
                    exact={true}
                    path="/mypage/order/return/:type/:status/:id/:productId"
                    element={<OrderReturn language={languageType}/>}
                />
                <Route
                    key={`public-route-/return/:id/:apiType/:type`}
                    exact={true}
                    path="/return/:id/:apiType/:type"
                    element={token ? <Return language={languageType}/> : <Private/>}
                />
                <Route
                    key={`public-route-/report/detail/:barcode/:id`}
                    exact={true}
                    path="/report/detail/:barcode/:id"
                    element={token ? <ReportDetail language={languageType}/> : <Private/>}
                />
                <Route
                    key={`public-route-/report/addSpouse`}
                    exact={true}
                    path="/report/addSpouse"
                    element={token ? <AddSpouse rightOpenFunc={(id,title)=>rightOpen(id,title)}/> : <Private/>}
                />
                <Route
                    key={`public-route-/report/family`}
                    exact={true}
                    path="/report/family"
                    element={token ? <FamilyManag language={languageType} rightOpenFunc={(id,title)=>rightOpen(id,title)}/> : <Private/>}
                />
                <Route
                    key={`public-route-/member/terms/:type`}
                    exact={true}
                    path="/member/terms/:type"
                    element={!token ? <Terms rightOpenFunc={(id,title)=>rightOpen(id,title)}/> : <PublicOnly/>}
                />
                <Route
                    key={`public-route-/addBarcode`}
                    exact={true}
                    path="/addBarcode"
                    element={token ? <AddBarcode rightOpenFunc={(id,title)=>rightOpen(id,title)}/> : <Private/>}
                />
                <Route
                    key={`public-route-/collaboration`}
                    exact={true}
                    path="/collaboration"
                    element={<Collaboration rightOpenFunc={(id,title)=>rightOpen(id,title)}/>}
                />
                <Route
                    key={`public-route-/cs/faq`}
                    exact={true}
                    path="/cs/faq"
                    element={<Faq language={languageType}/>}
                />
                <Route
                    key={`public-route-/report/cardDetail/:barcode/:id/:type`}
                    exact={true}
                    path="/report/cardDetail/:barcode/:id/:type"
                    element={<CardDetail language={languageType}/>}
                />
                <Route
                    key={`public-route-/inquiry`}
                    exact={true}
                    path="/inquiry"
                    element={<Inquiry language={languageType}/>}
                />
                <Route
                    key={`public-route-/survey/:id/:type/:surveyType`}
                    exact={true}
                    path="/survey/:id/:type/:surveyType"
                    element={token ? <Survey language={languageType}/> : <Private/>}
                />
                <Route
                    key={`public-route-/*`}
                    exact={true}
                    path="/*"
                    element={<PublicOnly/>}
                />
            </Routes>
            <ToastPopup
                data={cookiePopupData}
            >
                <p className="cookiePopupText"><i className="notranslate">BabyDNA </i><i>uses cookies to enhance the content displayed to you and provide the best possible service experience. By clicking "Allow All Cookies," personalized benefits and information based on your preferences will be continuously provided. You can choose to allow or block cookies through "Cookie Settings" as needed. However, blocking certain types of cookies may impact the seamless delivery of personalized content.</i>
                <br/><br/><i>For detailed information about cookies and the reasons for their use, please refer to the </i><button type="button" onClick={()=>{rightOpen(4,"Cookie Policy")}}>Cookie Policy page</button><i>.</i></p>
            </ToastPopup>
            <RightPopup
                topTitle={"Cookie Settings"}
                addClass={cookieRightOpenType ? "active cookeiSelPopup" : " cookeiSelPopup"}
                closePopup={() => {setCookieRightOpenType(false)}}
                openType={cookieRightOpenType}
            >
                <CookieTitle
                    title={slideIndex == 0 ? "Essential Cookies" : slideIndex == 1 ? "Functional Cookies" : slideIndex == 2 ? "Performance Cookies" : "Marketing Cookies"}
                    text={slideIndex == 0 ? "Allow" : null}
                    chk={slideIndex == 0 ? false : true}

                    checkedType={slideIndex == 0 ? null : slideIndex == 1 ? cookieSelData?.funcCookieAccess : slideIndex == 2 ? cookieSelData?.performanceCookieAccess : cookieSelData?.marketingCookieAccess}
                    chkFunc={(e)=>{
                        let chkData = {...cookieSelData};
                        if(slideIndex == 1){
                            chkData.funcCookieAccess = e;
                        }else if(slideIndex == 2){
                            chkData.performanceCookieAccess = e;
                        }else if(slideIndex == 3){
                            chkData.marketingCookieAccess = e;
                        }
                        setCookieSelData(chkData);
                    }}
                    toggleId={slideIndex == 0 ? null : slideIndex == 1 ? "funcCookie_toggle" : slideIndex == 2 ? "performanceCookie_toggle" : "marketingCookie_toggle"}
                />
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    pagination={true}
                    onRealIndexChange={(e) => {setSlideIndex(e.realIndex)}}
                    modules={[Pagination]}
                >
                    <SwiperSlide>
                        <CookieContents
                            cookieText={cookieData}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <CookieContents
                            cookieText={funcCookieData}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <CookieContents
                            cookieText={performanceCookieData}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <CookieContents
                            cookieText={marketingCookieData}
                        />
                    </SwiperSlide>
                </Swiper>
                <BtnBox
                    boxType="fixed"
                    addClass="col2"
                >
                    <BtnItem
                        addClass="line"
                        contents={"Allow Only Selected Cookies"}
                        disabled={false}
                        func={() => {cookieAccess("sel");setCookieRightOpenType(false);setCookiePopupData(null)}}
                    />
                    <BtnItem
                        addClass=""
                        contents={"Allow all cookies"}
                        disabled={false}
                        func={() => {cookieAccess("all");setCookieRightOpenType(false);setCookiePopupData(null)}}
                    />
                </BtnBox>
            </RightPopup>
            <SpousePopup
                rightOpen={(id,title)=>{rightOpen(id,title)}}
                token={token}
            />
            <RightPopup
                topTitle={terms?.title}
                addClass={terms == "" ? "" : "active"}
                closePopup={() => {setTerms("");setRightOpenType(false);setTermsId("")}}
                openType={rightOpenType}
            >
                <div className="termsText toastui-editor-contents" dangerouslySetInnerHTML={{__html:terms?.text}}></div>
            </RightPopup>
            <LoadingBox openType={loadingBoxOpen}/>
            <LanguageReSet/>
        </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;