import { BtnItem } from "component/basic/btns";
import { ChkBox } from "component/basic/formItems";
import { scrollx_mouse } from "js/function";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

//메인 section
function MainSection(data){
    return (
        <div className={`mainSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    ); 
}

//메인 section 타이틀
function MainSectionTitle(data){
    return (
        <div className={`mainSectionTitleBox ${data.addClass ? data.addClass : ""}`}>
            {data.img ? <img src={data.img}/> : ""}
            <h1 className="mainSectionTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.titleCaption ? <p className="mainSectionTitleCaption" dangerouslySetInnerHTML={{__html:data.titleCaption}}/> : ""}
        </div>
    ); 
}

//메인 about 컨텐츠
function LikeBox(data){
    return (
        <div className={`mainLikeBox ${data.addClass ? data.addClass : ""}`}>
            <img src={`/assets/images/main/${data.img}.svg`}/>
            <h1 className="mainLikeTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="mainLikeText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    ); 
}

//메인 process 컨텐츠
function ProcessBox(data){
    return (
        <div className={`mainProcessBox ${data.addClass ? data.addClass : ""}`}>
            <div className="mainProcess_infoBox">
                <div className="mainProcess_titleBox">
                    <p className="mainProcess_number notranslate">{data.number}</p>
                    <h1 className="mainProcess_title">{data.title}</h1>
                </div>
                <p className="mainProcess_text" dangerouslySetInnerHTML={{__html:data.text}}/>
            </div>
            <img src={`/assets/images/main/${data.img}.svg`}/>
        </div>
    ); 
}

//메인 youtube 컨텐츠
function MainYoutube(data){
    return (
        <div className={`mainVideoBox ${data.fullSize ? "fullSize" : ""}`}>
            <iframe width="100%" height="100%" src={data.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    ); 
}

//메인 상단 비디오
function MainVideo(data){
    return (
        <div className={`mainVideoBox ${data.fullSize ? "fullSize" : ""}`}>
            <video muted playsInline autoPlay controls loop width="100%" height="auto">
				<source src={data.src} type="video/mp4"/>
			</video>
        </div>
    ); 
}

//메인 유전적 특징 슬라이드 컨텐츠
function MainSlideItem(data){
    return (
        <div className="mainSlideItem">
            <div className="mainSlideItem_img" style={{backgroundImage:`url('${data.img}')`}}/>
            <h1 className="mainSlideItem_name" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    ); 
}

//메인 가족 버튼
function MainFamilyBtn(data){
    return (
        <button type="button" className={`mainFamilyBtn ${data.sel == data.id ? "active" : ""} notranslate`} onClick={()=>{data.func(data.id,data.status,data.type,data.birthday,data.name,data.eventStatus,data.waybillNumber)}}>
            {data.name}
        </button>
    ); 
}

//메인 가족 버튼 박스
function MainFamily(data){
    let navigate = useNavigate();
    const scrollItem = useRef(null);

    useEffect(()=>{
        scrollx_mouse(scrollItem.current)
    },[]);

    return (
        <div className="mainFamily">
            <div className="mainFamilyList scrollCoverBox" ref={scrollItem}>
                {data.children}
            </div>
            <button type="button" onClick={()=>navigate("/addBarcode")} className="MainFamily_add"><img src="/assets/images/basic/add_min.svg"/><i>Add Family</i></button>
        </div>
    ); 
}

//메인 바코드 섹션용
function MainBoardBox(data){
    return (
        <div className="mainBoard">
            {data.children}
        </div>
    ); 
}

//메인 바코드 섹션
function MainBoard(data){
    return (
        <MainBoardBox>
            <div className="mainBoard_titleBox">
                {data.titleIcon ? <img src={data.titleIcon} className="mainBoard_icon"/> : ""}
                <h1 className="mainBoard_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                {data.titleCaption ? <div className="mainBoard_textBox">{data.titleCaption}</div> : ""}
                {data.titleLink ? <a className="mainBoard_link" href={data.titleLinkUrl} target="_blank" dangerouslySetInnerHTML={{__html:data.titleLink}}/> : ""}
                {data.timeCaption ? <p className="surveyCover_time"><img src="/assets/images/img/time_icon.svg"/><i>Estimated time: About 3 minutes</i></p> : ""}
            </div>
            {data.img ? 
            <div className="mainBoard_imgBox" style={data.imgStyle||""}>
                <img src={data.img} className="mainBoard_img"/>
            </div>
            :""}
            {data.btnText ? 
                <div className="mainBoard_btnTextBox">{data.btnText}</div>
            :""}
            {data.warningText ? 
                <div className="mainBoard_warningBox">
                    {data.warningIcon ? <img src="/assets/images/main/main_board_warning.svg"/> : ""}
                    <div className="mainBoard_warningTextBox">
                        {data.warningTitle ? <h2 className="mainBoard_warningTitle" dangerouslySetInnerHTML={{__html:data.warningTitle}}/> : ""}
                        <div className="mainBoard_warningText">{data.warningText}</div>
                    </div>  
                </div>
            :""}
            {data.chkTitle ? 
                <ChkBox
                    addClass=""
                    func={(e) => {data.chkFunc(e)}}
                    checkedType={data.chkType}
                    label={data.chkTitle}
                    chkFunc={null}
                />
            :""}
            {data.btn ? 
                <BtnItem
                    addClass={data.btnAddClass}
                    contents={data.btn}
                    disabled={data.btnChk ? data.btnChk : false}
                    func={() => {data.btnFunc()}}
                />
            :""}
            <img src="/assets/images/main/main_board_down.svg" className="mainBoard_down_icon"/>
        </MainBoardBox>
    ); 
}

export {MainSection,MainBoardBox,MainSectionTitle,LikeBox,ProcessBox,MainVideo,MainYoutube,MainSlideItem,MainFamilyBtn,MainFamily,MainBoard};