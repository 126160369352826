import { Html5Qrcode } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import { useRef } from 'react';

//qr 세팅
const Html5QrcodePlugin = (props) => {
    let width = document.querySelectorAll("#qrItem")[0] ? document.querySelectorAll("#qrItem")[0].clientWidth : window.innerWidth;
    let height = document.querySelectorAll("#qrItem")[0] ? document.querySelectorAll("#qrItem")[0].clientHeight : window.innerHeight;
    let cameraId = {facingMode: "environment"};
    const qrBox = useRef(null);
    const [settingChk,setSettingChk] = useState(false);
    
    function route(){
        if(props.setting){
            props.LoadingBoxOpen(true);
            startScanner();
        }else if(settingChk && window.scanner){
            stopScanner();
        }
    }
    
    function startScanner(){
        width = document.querySelectorAll("#qrItem")[0] ? document.querySelectorAll("#qrItem")[0].clientWidth : window.innerWidth;
        height = document.querySelectorAll("#qrItem")[0] ? document.querySelectorAll("#qrItem")[0].clientHeight : window.innerHeight;
    
        let aspectRatioChk = width / height;
        let reverseAspectRatio = height / width;
        let mobileAspectRatio = reverseAspectRatio > 1.5 ? reverseAspectRatio + (reverseAspectRatio * 12 / 100) : reverseAspectRatio;
        let agent = navigator.userAgent;
        let settingType = false;
        let iosChk = false;

        if(agent.match(/Android/i) || agent.match(/webOS/i) || agent.match(/iPhone/i) || agent.match(/iPad/i) || agent.match(/iPod/i) || agent.match(/BlackBerry/i) || agent.match(/Windows Phone/i)){
            settingType = true;
        }else{
            settingType = false;
        }

        if(agent.match(/iPhone/i) || agent.match(/iPad/i) || agent.match(/iPod/i)){
            iosChk = true;
        }else{
            iosChk = false;
        }

        window.scanner.start(
            cameraId,
            {
                focusMode: "continuous",
                fps: 10, 
                qrbox: {width: 350, height: 220},
                aspectRatio: settingType ? width < height ? mobileAspectRatio : aspectRatioChk : aspectRatioChk
            },
            props.qrCodeSuccessCallback
        ).then(function() {
            // if(!props.setting && settingChk && window.scanner){
            //     stopScanner()
            // }
            setTimeout(() => {
                if(settingType){
                    window.scanner.applyVideoConstraints({
                        focusMode: "continuous",
                        advanced: [{ zoom: iosChk ? 2.5 : 2 }],
                    });
                }
            }, 1000);
        }).catch(function(err) {
            alert(err)
        });
        
        function videoPlay(){
            document.querySelectorAll("#qrItem")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0].play();
        }
    
        const playSet = setInterval(() => {
            if(document.querySelectorAll("#qrItem")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0]){
                setTimeout(() => {
                    document.querySelectorAll("#qrItem")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0].setAttribute("muted",true);
                    document.querySelectorAll("#qrItem")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0] && document.querySelectorAll("#qrItem")[0].querySelectorAll("video")[0].setAttribute("oncanplay",videoPlay);
        
                    clearInterval(playSet);
                    props.LoadingBoxOpen(false);
                }, 1000);
            }else{
                setTimeout(() => {
                    props.LoadingBoxOpen(false);
                }, 1000);
            }
        }, 100);
    }
    
    function stopScanner() {
        if(window.scanner){
            window.scanner.stop().then(ignore => {
                console.log(ignore)
                clearScanner();
                props.LoadingBoxOpen(false);
            }).catch(err => {
                console.log(err)
            });
        }
    }
    
    function clearScanner() {
        window.scanner.clear()
        cameraId = {facingMode: "environment"}
    }

    useEffect(() => {
        setSettingChk(false);
    }, []);

    useEffect(() => {
        if(props.setting){
            window.scanner = new Html5Qrcode("qrItem");

            Html5Qrcode.getCameras().then(cameras => {
                let cameraBackArr = cameras.filter(el => el.label.indexOf("back") !== -1);
                let agent = navigator.userAgent;
    
                if(agent.match(/iPhone/i) || agent.match(/iPad/i) || agent.match(/iPod/i)){
                    cameraBackArr = cameras;
                }else{
                    cameraBackArr = cameras.filter(el => el.label.indexOf("back") !== -1); 
                }

                if(cameraBackArr.length > 0){
                    cameraId = cameraBackArr[cameraBackArr.length - 1].id;
                }else{
                    cameraId = {facingMode: "environment"}
                }

                setTimeout(() => {
                    route();     
                }, 100);
            }).catch(err => {
                cameraId = {facingMode: "environment"}

                setTimeout(() => {
                    route();     
                }, 100);
            });

            setTimeout(() => {
                setSettingChk(true);
            }, 1000);
        }else{
            setTimeout(() => {
                route();     
            }, 100);
        }
    }, [props.setting]);

    return (
        <div id="qrItem" ref={qrBox}/>
    );
};

export {Html5QrcodePlugin};