import { scrollx_mouse } from "js/function";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

//category item
function CategoryBox(data){
    const [value,setValue] = useState(data.val);
    const scrollItem = useRef(null);

    useEffect(() => {
        setValue(data.val);
    }, [data.val]);

    useEffect(()=>{
        scrollx_mouse(scrollItem.current)
    },[]);

    return (
        <div className={`categoryBox scrollCoverBox ${data.addClass ? data.addClass : ""}`} ref={scrollItem}>
            {data.allBtn ? 
                <button type="button" onClick={() => data.func("")} className={`categoryItem ${"" == value ? "active" : ""}`}>All</button>
            :""}
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`categoryItem ${item.id == value ? "active" : ""}`}>{item.category_name}</button>
            ))}
        </div>
    );
}

//category item
function CategoryDnaBox(data){
    const [value,setValue] = useState(data.val);
    const scrollItem = useRef(null);

    useEffect(() => {
        setValue(data.val);
    }, [data.val]);

    useEffect(()=>{
        scrollx_mouse(scrollItem.current)
    },[]);

    return (
        <div className={`categoryBox scrollCoverBox ${data.addClass ? data.addClass : ""}`} ref={scrollItem}>
            {data.allBtn ? 
                <button type="button" onClick={() => data.func("")} className={`categoryItem ${"" == value ? "active" : ""}`}>All</button>
            :""}
            {data.data?.map((item, i) => (
                <button type="button" key={item.type} onClick={() => data.func(item.type)} className={`categoryItem ${item.type == value ? "active" : ""}`}>{item.type}</button>
            ))}
        </div>
    );
}

//align
function AlignBox(data){
    return (
        <div className={`alignArea ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/basic/filter.svg"/>
            <div className="alignItem_list">
                {data.data?.map((item, i) => (
                    <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`alignItem ${item.id == data.val ? "active" : ""}`}>{item.name}</button>
                ))}
            </div>
        </div>
    );
}

export {CategoryBox, AlignBox, CategoryDnaBox};