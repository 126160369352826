import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { BottomErrMsg, LoadingBox, Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const EmailEdit = (props) => {
    const navigate = useNavigate();

    const emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;

    const [popupData,setPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [btnChk,setBtnChk] =  useState(true);

    const [email,setEmail] =  useState("");
    const [emailErr,setEmailErr] = useState(null);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);
    
    //이메일 인증
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("The authentication time has expired.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(true);
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("email", email);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/email/certification`,
            loginType: "login",
            success: (data) => {
                timerStart();
                setCetified("");
                setCetifiedChk(true);

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //이메일 변경
    function editEmail(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("email", email);
        formData.append("code", cetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/mypage/email/modify`,
            loginType: "login",
            success: (data) => {
                setBtnChk(false);
                setCetifiedComChk(true);

                setTimerCount("3:00");
                setCertifyErr("");

                clearInterval(sval.current);
                sval.current = null;

                setBottomMsgData({
                    addClass : "",
                    text : "Email address has been updated.",
                    chk : bottomMsgData.chk + 1
                });

                setLoadingBoxOpen(false);

                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            },
            err: (data) => {
                if(data.alert == "An account is already registered with the email you entered."){
                    setPopupData({
                        addClass:null,
                        title:"This email address has already been registered.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }else if(data.data !== "" && data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }else if(data.alert){
                    setCertifyErr(data.alert);
                }

                setLoadingBoxOpen(false);
            }
        })
    }

    //버튼 유효성
    useEffect(() => {
        if(!emailRegExp.test(email) && email !== ""){
            setEmailErr("Please enter in email format")
        }else{
            setEmailErr(null)
        }
    }, [email]);

    useEffect(() => {
        if(cetified.length == 6 && emailRegExp.test(email)){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [cetified]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageClose={true}
                closeFunc={() => {navigate(-1)}}
                headTitle={"Email Verification"}
                centerTitle={true}
            />
            <MemberPage>
                <MemberTitle
                    contents="<i>To receive the authentication code,</i><br/><span>an email address</span><i> is required.</i>"
                />
                <MemberInputSection>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="Email Address"
                        placeholder="example@email.com"
                        value={email}
                        max={null}
                        regexp={null}
                        errMsg={emailErr}
                        func={(value)=>{setEmail(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                        btnAddClass={`${cetifiedChk || emailRegExp.test(email) ? "pBg" : ""}`}
                        btnDisabled={!emailRegExp.test(email)}
                        clickEv={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                        btnName={`${cetifiedChk ? "Resend" : "Verify"}`}
                        caption="Please authenticate with the email you check most frequently. Important notifications from BabyDNA, such as orders, completion of genetics tests, and adding of partner, will be sent to this email."
                    />
                    {cetifiedChk ? 
                    <InputItemBox
                        inputType={null}
                        addClass="cetifiedInput"
                        inputName="Verify"
                        placeholder=""
                        value={cetified}
                        max={6}
                        readOnly={cetifiedComChk}
                        cetified={timerCount}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setCetified(value)}}
                        errMsg={certifyErr}
                        caption="<i>Enter Verification Code</i><br/><i>If you haven't received the email, check your spam folder.</i>"
                    />
                    :""}
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"Verified"}
                        disabled={btnChk}
                        func={() => {editEmail()}}
                    />
                </BtnBox>
            </MemberPage>
            <Popup
                data={popupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default EmailEdit;