import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { BottomErrMsg, Popup, SelPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { phoneChk } from "js/function";

const Addr = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [btnChk,setBtnChk] =  useState(true);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [name,setName] =  useState("");
    const [phone,setPhone] =  useState("");
    const [province,setProvince] = useState(null);
    const [city,setCity] = useState(null);
    const [district,setDistrict] = useState(null);
    const [subdistrict,setSubdistrict] = useState(null);
    const [editAddrDetail,setEditAddrDetail] = useState("");

    const [phoneErr,setPhoneErr] =  useState(null);

    const [provinceAddr,setProvinceAddr] = useState([]);
    const [cityAddr,setCityAddr] = useState([]);
    const [districtAddr,setDistrictAddr] = useState([]);
    const [subdistrictAddr,setSubdistrictAddr] = useState([]);

    const [provinceAddrText,setProvinceAddrText] = useState([]);
    const [cityAddrText,setCityAddrText] = useState([]);
    const [districtAddrText,setDistrictAddrText] = useState([]);
    const [subdistrictAddrText,setSubdistrictAddrText] = useState([]);

    //기본 배송지 정보
    const addrApi = useGet({
        url:localStorage.getItem("token") ? "/mypage/delivery" : null,
        loginType:"login"
    });
    const addrData = addrApi?.data?.default_delivery;

    //주소 정보
    const provinceApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:"login"
    });
    const provinceData = provinceApi?.data?.types;

    const cityApi = useGet({
        url: province ? `/product/delivery/address?type=city&parent_id=${province.id}` : null,
        loginType:"login"
    });
    const cityData = cityApi?.data?.types||[];

    const districtApi = useGet({
        url: city ? `/product/delivery/address?type=district&parent_id=${city.id}` : null,
        loginType:"login"
    });
    const districtData = districtApi?.data?.types||[];

    const subdistrictApi = useGet({
        url: district ? `/product/delivery/address?type=subdistrict&parent_id=${district.id}` : null,
        loginType:"login"
    });
    const subdistrictData = subdistrictApi?.data?.types||[];

    //배송지 변경
    function editAddr(){
        let formData = new FormData();
        formData.append("address_name", name);
        formData.append("address_phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("address_province", province?.text);
        formData.append("address_city", city?.text);
        formData.append("address_district", district?.text);
        formData.append("address_subdistrict", subdistrict?.text);
        formData.append("address_detail", editAddrDetail);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/mypage/delivery/modify`,
            loginType: "login",
            success: (data) => {
                setBtnChk(false);
                setBottomMsgData({
                    addClass : "",
                    text : "Address has changed succesfully.",
                    chk : bottomMsgData.chk + 1
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배송지 세팅
    useEffect(() => {
        if(addrData){
            setName(addrData?.address_name);
            setPhone(addrData?.address_phone);
            setProvince({id:addrData?.province_id,text:addrData?.address_province});
            setEditAddrDetail(addrData?.address_detail);
            setTimeout(() => {
                setCity({id:addrData?.city_id,text:addrData?.address_city});
                setTimeout(() => {
                    setDistrict({id:addrData?.district_id,text:addrData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:addrData?.sub_district_id,text:addrData?.address_subdistrict});
                    }, 100);
                }, 100);
            }, 100);
        }
    }, [addrApi]);

    //주소 변경 이벤트
    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceData){
            provinceData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceData.length - 1 == i){
                    setProvinceAddr(setData);
                    setProvinceAddrText(setTextData);
                }
            });
        }else{
            setProvinceAddr(setData);
            setProvinceAddrText(setTextData);
        }
    }, [provinceData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(cityData){
            cityData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(cityData.length - 1 == i){
                    setCityAddr(setData);
                    setCityAddrText(setTextData);
                }
            });
        }else{
            setCityAddr(setData);
            setCityAddrText(setTextData);
        }
    }, [cityData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtData){
            districtData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtData.length - 1 == i){
                    setDistrictAddr(setData);
                    setDistrictAddrText(setTextData);
                }
            });
        }else{
            setDistrictAddr(setData);
            setDistrictAddrText(setTextData);
        }
    }, [districtData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictData){
                subdistrictData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictData.length - 1 == i){
                        setSubdistrictAddr(setData);
                        setSubdistrictAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictAddr(setData);
                setSubdistrictAddrText(setTextData);
            }
    }, [subdistrictData]);

    useEffect(() => {
        setCity(null);
        setDistrict(null);
        setSubdistrict(null);

        setCityAddr([]);
        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setCityAddrText([]);
        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [province]);

    useEffect(() => {
        setDistrict(null);
        setSubdistrict(null);

        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [city]);

    useEffect(() => {
        setSubdistrict(null);

        setSubdistrictAddr([]);

        setSubdistrictAddrText([]);
    }, [district]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    //버튼 유효성
    useEffect(() => {
        if(name !== ""
        && phoneChk(phone)
        && province
        && city
        && district
        && subdistrict
        && editAddrDetail !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [name,phone,province,city,district,subdistrict,editAddrDetail]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageClose={true}
                closeFunc={() => {navigate(-1)}}
                headTitle={"Register Default Shipping Address"}
                centerTitle={true}
            />
            <MemberPage>
                <MemberInputSection>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="Name"
                        placeholder="Name"
                        value={name}
                        max={null}
                        regexp={null}
                        func={(value)=>{setName(value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass="inputSubCaptionItem"
                        inputName="Mobile Phone Number"
                        placeholder="6201234567890"
                        value={phone}
                        max={16}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        errMsg={phoneErr}
                        func={(value)=>{setPhone(value)}}
                    ><p className="inputSubCaption_text">+</p></InputItemBox>
                    <InputItemBox
                        type="selItem"
                        addClass="type2"
                        inputName="Province"
                        placeholder="Select Province"
                        value={province ? province?.text : ""}
                        readOnly={true}
                        notranslate={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select Province",
                            addClass:null,
                            closeType:true,
                            items:provinceAddrText,
                            val:provinceAddr,
                            sel:province ? province?.text : "",
                            closeFunc:(e) => {},
                            func:(e) => {
                                setProvince({
                                    id:e.val,
                                    text:e.text
                                });
                            }
                        })}
                    />
                    <InputItemBox
                        type="selItem"
                        addClass="type2"
                        inputName="City"
                        placeholder="Select City"
                        value={city ? city?.text : ""}
                        readOnly={true}
                        disabled={province && cityAddr.length > 0 ? false : true}
                        notranslate={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select City",
                            addClass:null,
                            closeType:true,
                            items:cityAddrText,
                            val:cityAddr,
                            sel:city ? city?.text : "",
                            closeFunc:(e) => {},
                            func:(e) => {
                                setCity({
                                    id:e.val,
                                    text:e.text
                                });
                            }
                        })}
                    />
                    <InputItemBox
                        type="selItem"
                        addClass="type2"
                        inputName="District"
                        placeholder="Select District"
                        value={district ? district?.text : ""}
                        readOnly={true}
                        disabled={city && districtAddr.length > 0 ? false : true}
                        notranslate={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select District",
                            addClass:null,
                            closeType:true,
                            items:districtAddrText,
                            val:districtAddr,
                            sel:district ? district?.text : "",
                            closeFunc:(e) => {},
                            func:(e) => {
                                setDistrict({
                                    id:e.val,
                                    text:e.text
                                });
                            }
                        })}
                    />
                    <InputItemBox
                        type="selItem"
                        addClass="type2"
                        inputName="Subdistrict"
                        placeholder="Select Subdistrict"
                        value={subdistrict ? subdistrict?.text : ""}
                        readOnly={true}
                        disabled={district && subdistrictAddr.length > 0 ? false : true}
                        notranslate={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select Subdistrict",
                            addClass:null,
                            closeType:true,
                            items:subdistrictAddrText,
                            val:subdistrictAddr,
                            sel:subdistrict ? subdistrict?.text : "",
                            closeFunc:(e) => {},
                            func:(e) => {
                                setSubdistrict({
                                    id:e.val,
                                    text:e.text
                                });
                            }
                        })}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="Detailed Address"
                        placeholder="Detailed Address"
                        value={editAddrDetail}
                        max={null}
                        regexp={null}
                        func={(value)=>{setEditAddrDetail(value)}}
                    />
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"Complete"}
                        disabled={btnChk}
                        func={() => {editAddr()}}
                    />
                </BtnBox>
            </MemberPage>
            <SelPopup
                data={selPopupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Addr;