import { PhoneSetting, birthSetting, comFormat, scrollx_mouse } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import * as fatchSet from "api/api";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from "react";
import { useCallback } from "react";
import { BottomErrMsg } from "./popup";

//input box
function InputBoxOnly(data){
    return (
        <div className={`inputItemBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            {data.children}
        </div>
    );
}

//기본 input
function InputItemBox(data){
    const [count,setCount] = useState(0);
    const [pwCh,setPwCh] = useState(true);
    const inputItem = useRef(null);

    function valSetting(e){
        let value = e;

        if(value){
            if(data.regexp == "countNumber"){
                value = value.replace(/[^0-9]/gi,"");
                if(value.length == 1 && value == 0){
                    value = "";
                }
                if(value >= data.maxCount){
                    value = String(data.maxCount);
                }
            }else if(data.regexp == "number"){
                value = value.replace(/[^0-9]/gi,"");
                if(value >= data.maxCount){
                    value = String(data.maxCount);
                }
            }else if(data.regexp){
                value = value.replace(data.regexp,"");
            }

            if(data.phoneSetting){
                value = PhoneSetting(value).slice(0, 13)
            }else if(data.registrationType){
                value = value.slice(0, 6)
            }else if(data.birthSetting){
                value = birthSetting(value).slice(0, 10)
            }else{
                value = data.max ? value.slice(0, data.max) : value
            }
        }
        return value ? value : "";
    }

    useEffect(()=>{
        inputItem.current.value = data.value ? valSetting(data.value) : "";
    },[data.pageSetting])

    return (
        <div className={`inputItemBox ${(data.value !== "" || data.value2 !== "") && (data.value || data.value2) ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName"><i dangerouslySetInnerHTML={{__html:data.inputName}}></i>{data.tooltip||""}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className={`inputSet ${data.errMsg && data.errMsg !== "" ? "err" : ""} ${data.unit ? "inputUnitComBox" : ""} ${data.selItem ? "selItem" : ""} ${data.btnName ? "inputComBox" : ""} ${data.maxChk ? "maxChk" : ""} ${data.pwChType ? "pwBox" : ""} ${data.registrationType ? "registrationNumberBox" : ""}`}>
                    {data.selItem ? <p onClick={(e) => {if(data.clickInput && !data.disabled)data.clickInput(e)}} className={`inputItem inputPItem ${valSetting(data.value) ? "onText" : ""} ${data.notranslate ? "notranslate" : ""} ${data.disabled ? "disabled" : ""}`}>{valSetting(data.value) ? valSetting(data.value) : data.placeholder||""}</p>:""}
                    <input ref={inputItem} type={data.pwChType ? pwCh ? "password" : "text" : data.inputType ? data.inputType : "text"} onClick={(e) => {if(data.clickInput)data.clickInput(e)}} inputMode={data.inputMode ? data.inputMode : "text"} pattern={data.pattern ? data.pattern : ""} value={valSetting(data.value)} max={data.max && data.max !== "" ? data.max : "null"} className={`inputItem ${data.selItem ? "hideInputItem" : ""}`} onInput={(e) => {data.func(valSetting(e.target.value))}} onPaste={(e) => {data.func(valSetting(e.target.value))}} onChange={(e) => {data.func(valSetting(e.target.value))}} onKeyUp={(e) => {data.func(valSetting(e.target.value))}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    
                    {data.cetified || data.btnName ? 
                    <div className="btn_inputSubBtnBox">
                        {data.cetified ? 
                            <p className="cetifiedTimer">{data.cetified}</p>
                        :""}
                        {data.btnName ? 
                            <button type="button" className={`btn_inputSubBtn ${data.btnAddClass ? data.btnAddClass : ""}`} disabled={data.btnDisabled} onClick={(e) => data.clickEv(e)}>{data.btnName}</button>
                        :""}
                    </div>
                    :""}
                    
                    {data.maxChk ? 
                        <p className="textCount"><span>{count >= data.max ? data.max : count}</span> / {data.max}{data.unit ? data.unit : ""}</p>
                    :""}
                    {data.pwChType ? 
                        <button type="button" className={`btn_pwCh ${pwCh ? "" : "active"}`} onClick={()=>setPwCh(!pwCh)}>{pwCh ? <img src="/assets/images/basic/pw_on.svg"/> : <img src="/assets/images/basic/pw_off.svg"/>}</button>
                    : ""}
                    {data.unit ? <p className="inputUnit">{data.unit}</p> : ""}
                    {data.registrationType ?
                        <div>
                        <span>-</span>
                        <div className="registrationNumberBackBox">
                            <input type="number" value={data.value2.slice(0, 1)} onInput={(e) => {data.func2(e.target.value.slice(0, 1))}} onPaste={(e) => {data.func2(e.target.value.slice(0, 1))}} onChange={(e) => {data.func2(e.target.value.slice(0, 1))}} onKeyUp={(e) => {data.func2(e.target.value.slice(0, 1))}} inputMode="numeric" pattern="[0-9]*" className="inputItem" placeholder={data.placeholder2 && data.placeholder2 !== "" ? data.placeholder2 : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                            <p className="registrationNumberBack_text">******</p>
                        </div>
                        </div>
                    : ""}
                    {data.children}
                </div>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
        </div>
    );
}

//customSelect
function CustomSelect(data){
    const [optionOpen,setOptionOpen] = useState(false);
    const box = useRef(null);
    const optionBox = useRef(null);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setOptionOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [optionOpen, box]
    );

    const onChangeEvn = (name,val) => {
        data.func(val);
        setOptionOpen(false);
        window.removeEventListener("click",closeClick);
    };

    const onClickEvn = () =>{
        setOptionOpen(!optionOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    function valSetting(){
        let setAddr = [...data.options];
        let setData = setAddr.filter((el) => el[data.valKey] == data.value);
        return setData.length > 0 ? setData[0][data.nameKey] ? setData[0][data.nameKey] : "" : "";
    }

    useEffect(()=>{
        optionBox.current.scrollTop = 0;
    },[optionOpen]);
    
    return (
        <div className={`inputItemBox ${valSetting() !== "" ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`} ref={box}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName notranslate" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox customSelectInputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""} ${optionOpen ? "active" : ""}`} onClick={onClickEvn}>
                <input type="text" value={valSetting()} className="inputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true} disabled={data.disabled ? data.disabled : false}/>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
            <div className={`customSelect_optionBox ${optionOpen ? "active" : ""}`} ref={optionBox}>
                {data.options && data.options?.map((item,i)=>(
                    <button type="button" key={i} className={`customSelect_option ${data.value == item[data.valKey] ? "active" : ""} ${data.notLanguageCh ? "notranslate" : ""}`} onClick={()=>{onChangeEvn(item[data.nameKey],item[data.valKey])}} dangerouslySetInnerHTML={{__html:item[data.nameKey]}}></button>
                ))}
            </div>
        </div>
    );
}

//옵션 선택
function OptionSelect(data){
    const [optionOpen,setOptionOpen] = useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const box = useRef(null);
    const optionBox = useRef(null);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setOptionOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [optionOpen, box]
    );

    const onChangeEvn = (val) => {
        if(val.qty == 0){
            setBottomMsgData({
                addClass : "",
                text : "Options that are sold out cannot be selected.",
                chk : bottomMsgData.chk + 1
            });
        }else if(data?.selData?.filter((dataArr)=>{return dataArr.option_id == val.id}).length > 0){
            setBottomMsgData({
                addClass : "",
                text : "This option has already been selected.",
                chk : bottomMsgData.chk + 1
            });
        }else{
            data.func(val);
        }
        setOptionOpen(false);
        window.removeEventListener("click",closeClick);
    };

    const onClickEvn = () =>{
        setOptionOpen(true);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    useEffect(()=>{
        optionBox.current.scrollTop = 0;
    },[optionOpen]);

    return (
        <div>
        <div className={`inputItemBox ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`} ref={box}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox optionSelectInputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""} ${optionOpen ? "active" : ""}`}>
                <input type="text" className="inputItem" onClick={onClickEvn} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true} disabled={data.disabled ? data.disabled : false}/>
            </div>
            <div className={`optionSelect_optionBox ${optionOpen ? "active" : ""}`} ref={optionBox}>
                {data.options && data.options?.map((item,i)=>(
                    <button type="button" key={i} className={` ${data?.selData?.filter((dataArr)=>{return dataArr.option_id == item.id}).length > 0 || item.qty == 0 ? "active" : ""}`} 
                    onClick={()=>{onChangeEvn(item)}}>
                        <p className="optionSelect_optionName">{item.option_name}</p>
                        {item.option_price > 0 ? <p className="optionSelect_optionPrice notranslate">+Rp {comFormat(item.option_price)}</p> : ""}
                    </button>
                ))}
            </div>
        </div>
        <BottomErrMsg
            addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
            text={bottomMsgData.text ? bottomMsgData.text : ""}
            chk={bottomMsgData.chk}
        />
        </div>
    );
}

//textarea
function TextAreaItem(data){
    const [count,setCount] = useState(0);

    function valSetting(e){
        let value = e;
        value = data.max ? e.slice(0, data.max) : e;
        return value;
    }

    function valSettingCount(e){
        setCount(e.length >= data.max ? data.max : e.length);
    }

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className={`textAreaBox ${data.maxChk ? "maxChk" : ""} ${data.btnName ? "textAreaBtnComBox" : ""}`}>
                    <textarea value={valSetting(data.value)||""} className="textareaItem" max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickInput)data.clickInput(e)}} onInput={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onPaste={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onChange={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onKeyUp={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onKeyDown={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}></textarea>
                    {data.maxChk ? <p className="textareaCount"><span>{count >= data.max ? data.max : count}</span> / {data.max}{data.unit ? data.unit : ""}</p> : ""}
                    {data.btnName ? 
                        <div className="btn_inputSubBtnBox">
                            <button type="button" className={`btn_inputSubBtn ${data.btnAddClass ? data.btnAddClass : ""}`} disabled={data.btnDisabled} onClick={(e) => data.clickEv(e)}>{data.btnName}</button>
                        </div>
                    :""}
                </div>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
        </div>
    );
}

//파일첨부(api호출형)
function FileItem(data,url){
    const [val,setVal] = useState(data.value ? data.value : "");
    const [err,setErr] = useState(data.errMsg ? data.errMsg : "");
    const fileInput = useRef(null);

    function changeFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let formData = new FormData();
                formData.append("card_image", input.files[0]);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    url: url,
                    loginType:"login",
                    success: (data) => {
                        setErr("");
                        setVal(data.data.card_image_url);
                        e.target.value = "";
                    },
                    err: (data) => {
                        setErr(data.alert);
                    }
                });
            }
            reader.readAsDataURL(input.files[0]);
            
            return input.files[0];
        }
    }

    function delImage(){
        setErr("");
        setVal("");
        fileInput.current.value = "";
    }

    useEffect(() => {
        data.func(val);
        setErr("");
    }, [val]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className="inputFileBox">
                    <input type="file" onChange={(e) => changeFile(e.target)} accept="image/*" ref={fileInput} id={data.id} style={{display:"none"}}/>
                    <label htmlFor={data.id} style={{backgroundImage:`url('${val}')`}}>
                        <button type="button" className="delImage" onClick={()=>delImage()} style={{display:`${val == "" ? "none" : "block"}`}}>
                            <img src="/assets/images/basic/del.svg"/>
                        </button>
                        <div className="inputFile_label_basic" style={{display:`${val == "" ? "flex" : "none"}`}}>
                            <img src="/assets/images/basic/upload.svg"/>
                            <p className="inputFile_label_text">이미지를 업로드 해주세요</p>
                        </div>
                    </label>
                </div>
                {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
            </div>
        </div>
    );
}

//파일첨부(다중)
function FileItemMultipleItem(data){
    const [img,setImg] = useState("");
    const [setItem,setSetItem] = useState(false);

    let reader = new FileReader();
    reader.onload = function(e){
        setImg(e.target.result && e.target.result);
    }
    reader.readAsDataURL(data.data);

    useEffect(()=>{
        if(img !== "") setSetItem(true);
    },[img]);

    return (
        <div>{setItem ? 
        <div className="fileItemMultipleItem" style={{backgroundImage:`url('${img}')`}}>
            <button type="button" className="fileItemMultipleItemDel" onClick={()=>data.delFunc()}>
                <img src="/assets/images/basic/fileDel.svg"/>
            </button>
        </div>
        :""}</div>
    );
}

function FileItemMultiple(data){
    const [err,setErr] = useState(data.errMsg ? data.errMsg : "");

    const [inputFiles,setInputFiles] = useState([]);
    const [resetInput,setResetInput] = useState(0);

    const fileInput = useRef(null);
    const scrollItem = useRef(null);

    let mousedownChk = false,
        startPoint,
        scrollSize;

    let addfiles = [];
    let setAddFiles = [];

    function changeFile(input) {
        setAddFiles=[];
        if(data.max){
            let setInputArr = [...inputFiles, ...input.files];
            let setInputArrSplice = setInputArr.splice(0,data.max);
            setInputFiles([...setInputArrSplice]);
        }else{
            setInputFiles([...inputFiles, ...input.files]);
        }

        setErr("");
    }

    function inputReset(){
        fileInput.current.value = "";
    }

    function addFileFunc(item){
        setAddFiles.push(item);
    }

    function addFileSetting(){
        addfiles = [...addfiles, setAddFiles];
    }

    useEffect(()=>{
        data.func(...addfiles);
    },[addfiles]);

    useEffect(()=>{
        let reSetData = [];
        setInputFiles(reSetData);
        setResetInput(0);
    },[data.dataReset]);

    useEffect(()=>{
        scrollx_mouse(scrollItem.current);
    },[]);

    useEffect(()=>{
        let slideItem = document.querySelectorAll(".inputFileList")[0];
        slideItem.addEventListener("mousedown",(e)=>{
            mousedownChk = true;
            slideItem.classList.add("active")

            startPoint = e.pageX - slideItem.offsetLeft;
            scrollSize = slideItem.scrollLeft;
        })
        slideItem.addEventListener("mouseleave",()=>{
            mousedownChk = false;
            slideItem.classList.remove("active")
        })
        slideItem.addEventListener("mouseup",()=>{
            mousedownChk = false;
            slideItem.classList.remove("active")
        })
        slideItem.addEventListener("mousemove",(e)=>{
            if(!mousedownChk) return;

            e.preventDefault();
            let x = e.pageX - slideItem.offsetLeft,
                gapSize = (x - startPoint) * 1;
            slideItem.scrollLeft = scrollSize - gapSize;
        })

        return () => {
            let slideItem = document.querySelectorAll(".inputFileList")[0];
            if(slideItem){
                slideItem.removeEventListener("mousedown",(e)=>{
                    mousedownChk = true;
                    slideItem.classList.add("active")

                    startPoint = e.pageX - slideItem.offsetLeft;
                    scrollSize = slideItem.scrollLeft;
                })
                slideItem.removeEventListener("mouseleave",()=>{
                    mousedownChk = false;
                    slideItem.classList.remove("active")
                })
                slideItem.removeEventListener("mouseup",()=>{
                    mousedownChk = false;
                    slideItem.classList.remove("active")
                })
                slideItem.removeEventListener("mousemove",(e)=>{
                    if(!mousedownChk) return;

                    e.preventDefault();
                    let x = e.pageX - slideItem.offsetLeft,
                        gapSize = (x - startPoint) * 1;
                    slideItem.scrollLeft = scrollSize - gapSize;
                })
            }
        }
    },[]);

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className="inputFileBox">
                    <input type="file" multiple onChange={(e) => changeFile(e.target)} accept="image/*" ref={fileInput} id={data.id} style={{display:"none"}}/>
                    <div className="inputFileList scrollCoverBox" ref={scrollItem}>
                        {data.max && inputFiles.length >= data.max ? "" : <label htmlFor={data.id}>
                            <img src="/assets/images/basic/file_add.svg"/>
                        </label>}
                        {
                            inputFiles.length > 0 && inputFiles.map((item,i)=>(
                                <div key={i}>
                                    {item.type.split("/")[0] == "image" ? 
                                        (data.maxSise ? item.size <= (Number(data.maxSise) * 1024 * 1024) : true) ? 
                                            (data.max ? (addfiles.length + setAddFiles.length) < data.max : true) ? 
                                            <div>
                                                {addFileFunc(item)}
                                                <FileItemMultipleItem
                                                    data={item}
                                                    delFunc={()=>{
                                                        setErr("");

                                                        addfiles[0].splice(i,1);
                                                        let inputFileArr = [...inputFiles];
                                                        inputFileArr.splice(i,1);
                                                        setInputFiles(inputFileArr);
                                                        data.func(...addfiles);
                                                        setResetInput(resetInput + 1);
                                                    }}
                                                />
                                            </div>
                                            : ""
                                        : setErr("Unsupported file extension.")
                                    : setErr(`Attach images below the size of ${data.maxSize}.`)}
                                    {inputFiles.length - 1 == i && inputReset()}
                                    {inputFiles.length - 1 == i && addFileSetting()}
                                </div>
                            ))
                        }
                    </div>
                </div>
                {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
            </div>
        </div>
    );
}

//체크박스
function ChkBox(data){
    let id = `chk_item_${Math.floor(Math.random() * 1000)}`;
    return (
        <div className={`chk_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type="checkbox" value={data.val ? data.val : ""} onChange={(e) => data.func(e)} className="chk_itemChk" id={id} checked={data.checkedType||""} disabled={data.disabled ? data.disabled : false}/>
            <label htmlFor={id}>
                {data.children ? data.children : 
                    <p className="chk_item_text" dangerouslySetInnerHTML={{__html:`${data.chkSubType ? `<span>(${data.chkSubType})</span>` : ""} <i>${data.label}</i>`}}></p>
                }
            </label>
            {data.chkFunc ? <p className="chkBoxBtn" onClick={(e) => data.chkFunc(e)}>{data.chkBtnText ? data.chkBtnText : "View All"}</p> : ""}
            {data.chkTextFunc ? <div className="chkBox_moveText" onClick={(e) => data.chkTextFunc(e)} dangerouslySetInnerHTML={{__html:data.chkText}}/> : ""}
        </div>
    );
}

//라디오박스
function RadioBox(data){
    let id = `${data.id}_${Math.floor(Math.random() * 1000)}`;
    return (
        <div className={`radio_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type="radio" name={data.id} value={data.val} onChange={(e) => data.func(data.val)} className="radio_itemChk" id={id} checked={data.checkedType}/>
            <label htmlFor={id}>
                {data.colorType == "bgFullChk" ? <img src="/assets/images/basic/chk_icon_w.svg"/> : <img src="/assets/images/basic/chk_icon.svg"/>}
                <p className="radio_item_text" dangerouslySetInnerHTML={{__html:`${data.label}`}}></p>
            </label>
        </div>
    );
}

function TimeSelect(data){
    const [val,setVal] = useState(data.defaultValue ? data.defaultValue : data.options[0].val);

    useEffect(()=>{
        setVal(data.defaultValue ? data.defaultValue : data.options[0].val)
    },[data.chChk])

    return (
        <div className="timeSelectBox">
            <select className="timeSelectItem" value={val} onChange={(e)=>{data.func(e.target.value)}}>
                {
                    data.options.map((item,i)=>(
                        <option key={i} value={item.val}>{item.name}</option>
                    ))
                }
            </select>
        </div>
    );
}

//데이트피커 범위
function DatePicerRange(data){
    const [startDate,setStartDate] = useState("YYYY.MM.DD");
    const [endDate,setEndDate] = useState("YYYY.MM.DD");

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        data.func(start,end);
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input ref={ref} value={value == "" || !data.value ? "YYYY.MM.DD - YYYY.MM.DD" : value} onClick={onClick} type="text" className="inputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true} disabled={data.disabled ? data.disabled : false}/>
    ));

    const renderDayContents = (day, date) => {
        return <span>{day}</span>;
    };

    useEffect(()=>{
        if(!data.value){
            setStartDate("YYYY.MM.DD");
            setEndDate("YYYY.MM.DD");
        }
    },[data.dataChk]);

    let nowDate = new Date();

    return (
        <div className={`inputItemBox ${startDate !== "" && startDate ? "active" : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <ReactDatePicker
                    minDate={data.minCount ? new Date(nowDate.setDate(nowDate.getDate() + data.minCount)) : nowDate}
                    // locale={ko}
                    monthsShown={2}
                    closeOnScroll={false}
                    renderCalendarHeaderTitle={(date) => {}}
                    dateFormat="yyyy.MM.dd"
                    selected={startDate == "YYYY.MM.DD" ? "" : startDate}
                    onChange={onChange}
                    startDate={startDate == "YYYY.MM.DD" ? "" : startDate}
                    endDate={endDate == "YYYY.MM.DD" ? "" : endDate}
                    customInput={<CustomInput/>}
                    renderDayContents={renderDayContents}
                    selectsRange
                    // renderCustomHeader={({
                    //         monthDate,
                    //         customHeaderCount,
                    //         decreaseMonth,
                    //         increaseMonth,
                    //         prevMonthButtonDisabled,
                    //         nextMonthButtonDisabled
                    //     }) => (
                    //     <div>
                    //         <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" 
                    //             onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={customHeaderCount === 1 ? { visibility: "hidden" } : null} aria-label="Previous Month">
                    //             <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span>
                    //         </button>
                    //         <div className="react-datepicker__current-month">
                    //             {new Date(monthDate).getFullYear()}년 {new Date(monthDate).getMonth() + 1}월
                    //         </div>
                    //         <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next"
                    //             onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={customHeaderCount === 0 ? { visibility: "hidden" } : null} aria-label="Next Month">
                    //             <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
                    //         </button>
                    //     </div>
                    // )}
                />
            </div>
        </div>
    );
}

//데이트피커
function DatePicer(data){
    const [startDate,setStartDate] = useState("");

    const onChange = (date) => {
        const start = date;
        setStartDate(start);
        data.func(start);
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input ref={ref} value={value == "" ? "" : value} onClick={onClick} type="text" className="inputItem translate notranslate" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true} disabled={data.disabled ? data.disabled : false}/>
    ));

    const renderDayContents = (day, date) => {
        return <span>{day}</span>;
    };

    useEffect(()=>{
        if(!data.value){
            setStartDate("");
        }else{
            setStartDate(new Date(data.value.replace(/-/g,"/")));
        }
    },[data.dataChk]);

    let nowDate = new Date();

    return (
        <div className={`inputItemBox ${startDate !== "" && startDate ? "active" : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName" dangerouslySetInnerHTML={{__html:data.inputName}}></h2> : ""}
            <div className={`inputBox datePicerInputBox notranslate ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <ReactDatePicker
                    minDate={data.minCount ? new Date(nowDate.setDate(nowDate.getDate() + data.minCount)) : nowDate}
                    // locale={ko}
                    monthsShown={1}
                    closeOnScroll={false}
                    dateFormat="yyyy-MM-dd"
                    selected={startDate == "YYYY-MM-DD" ? "" : startDate}
                    onChange={onChange}
                    startDate={startDate == "YYYY-MM-DD" ? "" : startDate}
                    customInput={<CustomInput/>}
                    renderDayContents={renderDayContents}
                //     renderCustomHeader={({
                //         monthDate,
                //         customHeaderCount,
                //         decreaseMonth,
                //         increaseMonth,
                //         prevMonthButtonDisabled,
                //         nextMonthButtonDisabled
                //     }) => (
                //     <div>
                //         <button type="button" className="react-datepicker__navigation react-datepicker__navigation--previous" 
                //             onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={customHeaderCount === 1 ? { visibility: "hidden" } : null} aria-label="Previous Month">
                //             <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span>
                //         </button>
                //         <div className="react-datepicker__current-month">
                //             {new Date(monthDate).getFullYear()}년 {monthDate.toLocaleString('default', { month: 'short' })}
                //             {console.log(monthDate.toLocaleString('default', { month: 'short' }))}
                //         </div>
                //         <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next"
                //             onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={customHeaderCount === 0 ? { visibility: "hidden" } : null} aria-label="Next Month">
                //             <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
                //         </button>
                //     </div>
                // )}
                />
            </div>
        </div>
    );
}

export {InputBoxOnly, InputItemBox, ChkBox, TextAreaItem, FileItem, FileItemMultiple, RadioBox, DatePicerRange, DatePicer, CustomSelect, OptionSelect}