import { ToggleBtn } from "component/basic/btns";

//쿠키팝업 컨텐츠
function CookieTitle(data){
    return (
        <div className={`cookieTitleBox ${data.addClass ? data.addClass : ""}`}>
            <h1 className="cookieTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.text ? <p className="cookieTitleCaption">{data.text}</p> : ""}
            {data.chk ? 
                <ToggleBtn
                    checkedType={data.checkedType}
                    chkFunc={(e)=>{data.chkFunc(e)}}
                    id={data.toggleId}
                />
            : ""}
        </div>
    );
}

function CookieContents(data){
    return (
        <div className="cookieArea">
            <p className="cookieText" dangerouslySetInnerHTML={{__html:data.cookieText}}/>
        </div>
    );
}

export {CookieContents,CookieTitle};