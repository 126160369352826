import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { BtnBox, BtnItem, TextBtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { MemberTitle } from "component/member/items";
import { SubLine } from "component/basic/tap";
import { zendeskOpen } from "js/function";

const Inquiry = (props) => {
    const navigate = useNavigate();

    const [errPopupData,setErrPopupData] =  useState(null);

    const [pw,setPw] = useState("");
    const [orderNumber,setOrderNumber] = useState("");

    const [btnChk,setBtnChk] = useState(true);

    //비회원 주문 조회
    function inquiryStart(){
        fatchSet.FatchApi({
            type:"GET",
            loginType: localStorage.getItem("token") ? "login" : null,
            url: `/order/inquiry?orderNumber=${orderNumber}&password=${pw}`,
            success: (data) => {
                navigate(`/mypage/order/detail/0/${data?.data?.order_id}`,{state: {pw: pw,orderNumber:orderNumber}})
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //버튼 유효성
    useEffect(() => {
        if(orderNumber !== "" && pw.length > 7){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [pw,orderNumber]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Non-member Order Inquiry"
                centerTitle={true}
            />
            <MypageSection addClass="bottomBtn">
                <MemberTitle
                    contents="Please enter the order ID and order password."
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Order ID"
                    placeholder="Please enter the order ID."
                    value={orderNumber}
                    max={255}
                    regexp={null}
                    func={(value)=>{setOrderNumber(value)}}
                    caption={props?.language == "en" ? "Please enter the “Reference ID” found in the order confirmation email, excluding ”#”." : "Masukkan 'Reference ID' tanpa #, pada konfirmasi pesanan yang Anda terima melalui email."}
                />
                <InputItemBox
                    inputType="password"
                    addClass=""
                    inputName="Order Password"
                    placeholder="********"
                    value={pw}
                    max={20}
                    regexp={null}
                    func={(value)=>{setPw(value)}}
                    caption="8 to 20 characters containing a combination of English letters, numbers, and special characters"
                />
                <SubLine addClass="pageFull" margin="30px 0"/>
                <p className="inquiryCaption">Forgot your order password?</p>
                <TextBtnItem contents="Go to 1:1 Inquiry" func={()=>{
                    zendeskOpen();
                }}/>
            </MypageSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents="View"
                    disabled={btnChk}
                    func={() => {inquiryStart()}}
                />
            </BtnBox>
            <Popup
                data={errPopupData}
            />
        </PageSizing>
    );
};

export default Inquiry;