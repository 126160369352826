import { BtnBox, BtnItem } from "component/basic/btns";
import { ProductItem } from "component/product/detail";
import { comFormat, getDateGap, setDateTime, setDateWeek } from "js/function";
import { useNavigate } from "react-router-dom";

//주문 상태
function OrderCaption(data){
    return (
        <p className={`orderCaption ${data.addClass ? data.addClass : ""}`}>{data.text}</p>
    );
}

//환불,교환 반려 사유
function OrderListTextBox(data){
    return (
        <div className="orderListTextBox">
            <h1 className="orderListText_title">{data.title}</h1>
            <p className="orderListText_text">{data.text}</p>
        </div>
    );
}

function TransportNumberItem(data){
    return (
        <p className="orderTransportNumber">{data.statusType == "Order Kit" ? "Tracking Number" : "Redelivery Tracking Number"} : {data.transportNumber}</p>
    );
}

//주문 목록,상세 제품별 상세 안내
function OrderListItem(data){
    let navigate = useNavigate();

    return (
        <div className={`orderListItem ${data.addClass ? data.addClass : ""}`}>
            <button type="button" onClick={()=>{if(data.addClass !== "orderDetailItem"){navigate(`/mypage/order/detail/1/${data.id}`)}}} className="orderListItem_numberBox">
                {data.order_number ? <p className="orderListItem_number">{data.order_number}</p> : ""}
                {data.date && setDateWeek(data.date) ? <p className="orderListItem_date">{setDateWeek(data.date)}/{setDateTime(data.date,"yymmddhhtt","jakarta")}</p> : ""}
            </button>
            {data.caption ? <OrderCaption
                addClass={data.captionAddClass||""}
                text={data.caption}
            />:""}
            {data.addClass == "orderDetailItem" && data.statusType !== "Order Kit" ? 
            "" :
            <div>
            <ProductItem
                img={data.statusType == "Order Kit" ? data?.product?.thumb_image : "/assets/images/img/ReTesting_img.png"}
                name={data.statusType == "Order Kit" ? 
                data?.language == "en" ? data?.product?.product_name : data?.product?.product_name_id 
                : data?.language == "en" ? "Re-testing Kit" : "Kit Uji Ulang"}
                options={data.statusType == "Order Kit" ? data?.product?.option_name ? `${data?.product?.option_name} / ${data?.product?.qty ? comFormat(data?.product?.qty) : 1}EA` : `${data?.product?.qty ? comFormat(data?.product?.qty) : 1}EA` : "-"}
                priceClass=""
                sale_price={null}
                price={data.statusType == "Order Kit" ? data?.product?.total_price : "-"}
            />
            {data.transportNumber ? <TransportNumberItem text={`${data.statusType == "Order Kit" ? "Tracking Number" : "Redelivery Tracking Number"} : ${data.transportNumber}`}/> : ""}
            {data.statusType == "Order Kit" && (data.request_status || data.request_status == 0) && data.request_type !== "Exchange" && data.request_status !== 0 ? 
                <div>
                    {data.return_price ? <p className="orderTransportNumber notranslate">Rp{comFormat(data.return_price)}</p> : ""}
                    <p className="orderRefundText">* Refund amounts may be subject to deduction of fees and handling charges depending on the reason.</p>
                </div>
             : ""}
            {data.reason && data.addClass !== "orderDetailItem" ? 
                <OrderListTextBox
                    title={data.reasonTitle}
                    text={data.reason}
                />
            :""}
            {(data.is_cancel !== 1 && data.statusType == "Order Kit" && (data.request_status || data.request_status == 0) && data.request_type !== "Exchange" && data.request_status == 3 && data.refund_status == 2) || (data.is_cancel == 1 && data.cancel_status == 0) ? 
                <p className="orderDetailReasonText rColor">Please provide accurate bank account details, as we cannot assist refund issues caused by input errors. Your refund status can be tracked by clicking the [Claim Payout] button in the refund email.</p>
            :""}
            <BtnBox
                boxType=""
                addClass={true ? "col2 orderListBtnBox" : " orderListBtnBox"}
            >
                {data.is_cancel == 1 ? 
                    data.cancel_status == 1 ?
                        <BtnItem
                            addClass="line"
                            contents={"Add to Cart Again"}
                            disabled={false}
                            func={() => {data.func()}}
                        />
                    :""
                :
                data.statusType == "Order Kit" ? 
                    data.request_status || data.request_status == 0 ? 
                        data.request_type == "Exchange" ? 
                            data.request_status == 1 ? 
                                <BtnItem
                                    addClass=""
                                    contents={"Enter Return Information"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                            : data.request_status == 5 ? 
                                <BtnItem
                                    addClass="line"
                                    contents={"Register Barcode"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                            : ""
                        :
                            data.request_status == 1 ? 
                                <BtnItem
                                    addClass=""
                                    contents={"Enter Return Information"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                            : data.request_status == 3 ? 
                                data.refund_status == 2 ? 
                                    <BtnItem
                                        addClass="line"
                                        contents={"1:1 Inquiry"}
                                        disabled={false}
                                        func={() => {data.func()}}
                                    />
                                : data.refund_status == 1 ?
                                    <BtnItem
                                        addClass="line"
                                        contents={"Add to Cart Again"}
                                        disabled={false}
                                        func={() => {data.func()}}
                                    />
                                : "" 
                            : ""
                    :
                        data.status == 1 ? 
                            <BtnItem
                                addClass="line"
                                contents={"Cancel Order"}
                                disabled={false}
                                func={() => {data.func()}}
                            />
                        : data.status == 2 ? 
                            <BtnItem
                                addClass="line"
                                contents={"1:1 Inquiry"}
                                disabled={false}
                                func={() => {data.func()}}
                            />
                        : data.status == 3 ? 
                            <BtnItem
                                addClass="line"
                                contents={"1:1 Inquiry"}
                                disabled={false}
                                func={() => {data.func()}}
                            />
                        : data.status == 4 ?
                            data.reason ? 
                                <BtnItem
                                    addClass="line"
                                    contents={"1:1 Inquiry"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                            : getDateGap(data.receiptDate,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") > 7 ?
                                <BtnItem
                                    addClass=""
                                    contents={"Register Barcode"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                            :
                                <BtnItem
                                    addClass="line"
                                    contents={"Apply for Exchange"}
                                    disabled={false}
                                    func={() => {data.func()}}
                                />
                    : ""
                :
                    ""
                }
                {data.is_cancel !== 1 && (!data.request_status && data.request_status !== 0) ?
                    data.status == 4 ?
                        data.reason ? 
                            <BtnItem
                                addClass=""
                                contents={"Register Barcode"}
                                disabled={false}
                                func={() => {data.func2()}}
                            />
                        : getDateGap(data.receiptDate,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7 ? 
                            <BtnItem
                                addClass="line"
                                contents={"Apply for Refund"}
                                disabled={false}
                                func={() => {data.func2()}}
                            />
                        : ""
                    :""
                :""}
            </BtnBox>
            {data.is_cancel !== 1 && (!data.request_status && data.request_status !== 0) && data.status == 4 && !data.reason && getDateGap(data.receiptDate,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7 ?
                <BtnItem
                    addClass="orderListBtnItem"
                    contents={"Register Barcode"}
                    disabled={false}
                    func={() => {data.func3()}}
                />
            :""}
            {data.reason && data.addClass == "orderDetailItem" ? 
                <p className="orderDetailReasonText rColor">{data.reason}</p>
            :""}
            </div>}
        </div>
    );
}

//주문 상세 재배송 안내
function OrderDetailReTitleBox(data){
    return (
        <div className={`orderDetail_reDelivery_titleBox ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <img src={data.icon}/> : ""}
            <h1 className="orderDetail_reDelivery_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.subLink ? <a href={data.subLinkUrl} target="_blank" className="orderDetail_reDelivery_titleLink">{data.subLink}</a> : ""}
        </div>
    );
}

export {OrderCaption,OrderListItem,OrderDetailReTitleBox,TransportNumberItem};