import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductSelPriceData } from "component/product/list";
import { BtnBox, BtnItem, BtnSubBox } from "component/basic/btns";
import { OrderAddrAddr, OrderAddrBox, OrderAddrInfo, ProductItem, ProductItemBox, ProductItemTitle } from "component/product/detail";
import { SubLine } from "component/basic/tap";
import { comFormat, setDateTime, setDateWeek } from "js/function";

const OrderCom = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const orderApi = useGet({
        url:`/order/detail/direct?id=${id}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.order_info;

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="bottomBtn headerSet">
            <Header
                pageClose={false}
                closeFunc={() => {navigate(-1)}}
                headTitle="Payment Complete"
                centerTitle={true}
            />
            <div className="orderComSection">
                <img src="/assets/images/img/orderCom.svg"/>
                <h1 className="orderCom_title">Your order is complete.</h1>
                <p className="orderCom_text"><i>{orderData?.created_at ? `${setDateWeek(orderData?.created_at)}/` : ""}{orderData?.created_at ? setDateTime(orderData?.created_at,"yymmddhhtt","jakarta") : ""}</i><br/><i>The order ID for the ordered product is</i><br/><span>{orderData?.order_number ? orderData?.order_number : ""}</span></p>
            </div>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox>
                <ProductItemTitle
                    title="Ordered Items"
                />
                {orderData?.products && orderData?.products.map((item,i)=>(
                    <ProductItemBox addClass="multipleBox" key={i}>
                        <ProductItem
                            img={item?.thumb_image}
                            name={props.language == "en" ? item?.product_name : item?.product_name_id}
                            options={item?.option_name ? `${item.option_name} / ${item.qty ? comFormat(item.qty) : 1}EA` : `${item.qty ? comFormat(item.qty) : 1}EA`}
                            priceClass=""
                            sale_price={null}
                            price={item?.total_price}
                        />
                    </ProductItemBox>
                ))}
            </ProductItemBox>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox>
                <ProductItemTitle
                    title="Shipping Information"
                />
                <OrderAddrBox>
                    <OrderAddrInfo info={`${orderData?.address_name||""} ${orderData?.address_phone ? `(${orderData?.address_phone})` : ""}`}/>
                    <OrderAddrAddr addr={`${orderData?.address_detail||""}${orderData?.address_detail ? ", " : ""}${orderData?.address_subdistrict||""}${orderData?.address_subdistrict ? ", " : ""}${orderData?.address_district||""}${orderData?.address_district ? ", " : ""}${orderData?.address_city||""}${orderData?.address_city ? ", " : ""}${orderData?.address_province||""}`}/>
                </OrderAddrBox>
                {!localStorage.getItem("token") ? 
                <div>
                    <ProductItemTitle
                        title="Order Information"
                        caption="Order information has been sent to the email address below"
                    />
                    <OrderAddrBox>
                        <OrderAddrAddr addr="e-mail"/>
                        <OrderAddrInfo info={orderData?.order_email ? orderData?.order_email : ""}/>
                    </OrderAddrBox>
                </div>
                :""}
            </ProductItemBox>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox addClass="orderPriceBox">
                <ProductSelPriceData name="Total Payment" val={`Rp${comFormat(orderData?.total_price ? orderData?.total_price : 0)}`} notranslate={true} addClass="total"/>
                <ProductSelPriceData name="Product Price" val={`Rp${comFormat(orderData?.product_price ? orderData?.product_price : 0)}`} notranslate={true} addClass=""/>
                <ProductSelPriceData name="Shipping Fee" val={`Rp${comFormat(orderData?.delivery_price ? orderData?.delivery_price : 0)}`} notranslate={true} addClass=""/>
                <ProductSelPriceData name="Payment Method" val={orderData?.payment_method ? orderData?.payment_method : ""} notranslate={true} addClass=""/>
            </ProductItemBox>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnSubBox addClass="col2">
                    <BtnItem
                        addClass="line col2"
                        contents="Order Details"
                        disabled={false}
                        func={() => {
                            if(localStorage.getItem("token")){
                                navigate(`/mypage/order/detail/1/${id}`)
                            }else{
                                navigate(`/inquiry`)
                            }
                        }}
                    />
                    <BtnItem
                        addClass="col2"
                        contents="Go back to the homepage"
                        disabled={false}
                        func={() => {navigate("/")}}
                    />
                </BtnSubBox>
            </BtnBox>
        </PageSizing>
    );
};

export default OrderCom;