import { comFormat } from "js/function";
import { useNavigate } from "react-router-dom";
import CountInput from "./count";

//대량구매문의 링크
function BulkLink(data){
    let navigate = useNavigate();
    return (
        <button type="button" onClick={()=>{navigate("/cs/bulk")}} className={`btnMinLink ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/icon/info.svg"/>
            <p className="btnMinLink_text">Bulk Purchase</p>
            <img src="/assets/images/icon/move_min.svg"/>
        </button>
    );
}

//제품 목록 제품 항목
function ProductListItem(data){
    return (
        <div>
            <div className="productList_img" style={{backgroundImage:`url('${data.thumb_image}')`}}/>
            <h1 className="productList_name notranslate">{data.product_name}</h1>
            {data.description ? <p className="productList_info notranslate">{data.description}</p> : ""}
            <h2 className="productList_price notranslate">Rp {comFormat((data.sale_price ? data.sale_price : data.price||0).toFixed(2))}</h2>
            {data.sale_price ? 
            <div className="productList_saleBox notranslate">
                <p className="productList_sale">{Math.floor((1 - (Number(data.sale_price||0) / Number(data.price||0))) * 100)}%</p>
                <p className="productList_salePrice">Rp {comFormat((data.price||0).toFixed(2))}</p>
            </div>
            :""}
        </div>
    );
}

//제품 목록 제품 박스
function ProductListBox(data){
    return (
        <div className="productListBoxArea">
            {data.bulkLink ? <BulkLink/> : ""}
            <div className={`productListBox ${data.addClass ? data.addClass : ""}`} onClick={()=>{data.func()}}>
                {data.children}
            </div>
        </div>
    ); 
}

//메인 노출 제품 항목(현재 삭제)
function SubProduct(data){
    return (
        <div className={`subProductListBox ${data.addClass ? data.addClass : ""}`} onClick={()=>{data.func()}}>
            <h1 className="subProductList_name">{data.product_name}</h1>
            <div className="subProductList_priceBox">
            {data.sale_price ? 
                <p className="subProductList_salePrice">Rp {comFormat((data.price||0).toFixed(2))}</p>
                :""}
                <h2 className="subProductList_price">Rp {comFormat((data.sale_price ? data.sale_price : data.price||0).toFixed(2))}</h2>
            </div>
        </div>
    ); 
}

//주문 가격 안내 항목
function ProductSelPriceData(data){
    return (
        <div className={`productSelPriceDataBox ${data.addClass ? data.addClass : ""}`}>
            <p className="productSelPriceData_name">{data.name}</p>
            <p className={`productSelPriceData_val ${data.notranslate ? "notranslate" : ""}`}>{data.val}</p>
        </div>
    ); 
}

//제품 선택 항목 박스
function ProductSelBox(data){
    return (
        <div className={`productSelBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    ); 
}

//주문 가격 안내 통합
function ProductSelPriceBox(data){
    return (
        <div className={`productSelPrice ${data.addClass ? data.addClass : ""}`}>
            {/* <ProductSelPriceData name="Product Price" val={`Rp ${comFormat(data.productPrice||0)}`} notranslate={true} addClass=""/> */}
            {/* <ProductSelPriceData name="Shipping Fee" val={`Rp ${comFormat(data.deliveryPrice||0)}`} notranslate={true} addClass=""/> */}
            <ProductSelPriceData name="Price" val={`Rp ${comFormat(data.TotalPrice||0)}`} notranslate={true} addClass="total"/>
        </div>
    ); 
}

//제품 선택 항목
function ProductSelItem(data){
    return (
        <div className={`productSelItem ${data.addClass ? data.addClass : ""}`}>
            <div className="productSelItem_nameBox">
                <h1 className={`productSelItem_name ${data.notranslate ? "notranslate" : ""}`} dangerouslySetInnerHTML={{__html:data.name}}/>
                {data.del ? <button type="button" className="btn_productDel" onClick={()=>{data.delFunc(data.id)}}><img src="/assets/images/basic/del.svg"/></button> : ""}
            </div>
            <div className="productSelItem_priceBox">
                <p className="productSelItem_price" dangerouslySetInnerHTML={{__html:`Rp ${comFormat(data.price||0)}`}}/>
                <CountInput
                    val={data.countVal}
                    qty={data.qty}
                    func={data.countFunc}
                    excessFunc={data.excessFunc}
                    excessFunc_max={data.excessFunc_max}
                />
            </div>
        </div>
    ); 
}

export {ProductListItem, BulkLink, ProductListBox, SubProduct, ProductSelPriceData, ProductSelPriceBox,ProductSelItem,ProductSelBox};