import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup, ToastPopup } from "component/basic/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { SpouseAddInfoArea } from "component/report/items";
import { useLocation } from "react-router-dom";

const SpousePopup = (props) => {
    const [popupData,setPopupData] =  useState(null);
    const [spouseBtnChk,setSpouseBtnChk] =  useState(true);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [apiCount,setApiCount] =  useState(0);

    const spouseApi = useGet({
        url: props.token ? `/spouse?chk=${apiCount}` : null,
        loginType:"login"
    });
    const spouseData = props.token ? spouseApi?.data?.spouse : null;

    const locationType = useLocation();

    //배우자 거절
    function spouseRefuseOpen(id){
        let formData = new FormData();
        formData.append("id", id);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: `/family/refuse`,
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"The genetic chemistry invitation has been returned.",
                    text:"The genetic chemistry invitation has been declined.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{window.location.reload(true)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 승낙
    function spouseAcceptance(id){
        let formData = new FormData();
        formData.append("id", id);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: `/family/accept`,
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"Genetic Chemistry Invitation Accepted",
                    text:"<i>You have accepted the invitation.</i><br/><i>You can now find out your genetic chemistry with your partner, and check the DNA report of the child registered by your partner.</i>",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{window.location.reload(true)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 팝업 오픈
    function spouseOpen(){
        if(spouseData){
            setToastPopupData({
                addClass:null,
                title:"Partner Request",
                closeType:true,
                closeFuncChk:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    spouseRefuseOpen(spouseData?.id);
                },
                btn0:"Reject",
                btn0Type:"line",
                btn1:"Accept",
                btn1Type:"",
                btnFunc1:()=>{
                    spouseAcceptance(spouseData?.id);
                },
                btn1Disabled:spouseBtnChk,
                btnChkBox:true,
                chkType:!spouseBtnChk,
                btnChkFuncLabel:"[Required] Collection and Usage of Personal Information",
                btnChkFunc:(e)=>{{
                    setSpouseBtnChk(!e);
                }},
                btnChkClickFunc:()=>{props.rightOpen(26,"Collection and Usage of Personal Information")},
            });
        }
    }

    useEffect(()=>{
        spouseOpen();
    },[spouseBtnChk])

    // useEffect(()=>{
    //     setApiCount(apiCount + 1);
    // },[locationType])

    useEffect(()=>{
        spouseOpen();
    },[spouseApi])

    return (
        <div>
            <ToastPopup
                data={toastPopupData}
            >
                <SpouseAddInfoArea
                    title="<i>A genetic chemistry invitation has arrived.</i><br/><span>Do you want to accept the invitation and compare your genetic results?</span>"
                    name={spouseData?.name ? spouseData?.name : ""}
                    nickname={spouseData?.nickname ? spouseData?.nickname : ""}
                    phone={spouseData?.phone ? spouseData?.phone : ""}
                    text={"<i>You can find out your genetic chemistry with your partner,</i><br/><i>and check the DNA report of the child registered by your partner.</i>"}
                    titleCenter={true}
                />
            </ToastPopup>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default SpousePopup;