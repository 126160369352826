import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChemiDetailListItem, ChemiImgBox } from "component/report/items";
import { chemi_cateIcon } from "js/function";
import { PageSection } from "component/app/items";

const ChemiDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const category = param.category;
    
    const camiApi = useGet({
        url:`/family/cami/detail?barcode_id=${id}&category_name=${category}`,
        loginType:"login"
    });
    const camiData = camiApi?.data?.category;

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="gBg">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={`<img src="/assets/images/report/chemi_icon/${chemi_cateIcon(camiData?.category_name)}.svg"/> ${props.language == "en" ? camiData?.category_name : camiData?.category_name_id}`}
                notranslate_title={true}
                centerTitle={true}
            />
            <PageSection addClass="subPage">
                <ChemiImgBox>
                    <p className="chemiDetail_name"><i className="notranslate">{camiApi?.data?.nickname_my}</i><i className="notranslate"> </i><i>&</i><i className="notranslate"> </i><i className="notranslate">{camiApi?.data?.nickname}</i><i className="notranslate">'s</i><i className="notranslate"> </i><i className="notranslate">{props.language == "en" ? camiData?.category_name : camiData?.category_name_id}</i><i className="notranslate"> </i><i>chemistry is?</i></p>
                    <h2 className="chemiDetail_title notranslate">{props.language == "en" ? camiData?.cami : camiData?.cami_id}</h2>
                </ChemiImgBox>
                <div className="chemiDetail_countBox">
                    <h2 className="chemiDetail_count"><span>{camiData?.count}</span><i className="notranslate"> </i><i>out of</i><i className="notranslate"> </i><i>{camiData?.dna_count}</i><i className="notranslate"> </i><i>match well</i></h2>
                    <div className="chemiDetail_countList">
                        {camiData?.cami_array && camiData?.cami_array?.map((item,i)=>(
                            <ChemiDetailListItem
                                key={i}
                                addClass=""
                                func={()=>{navigate(`/report/detail/${id}/${item.report_id}`)}}
                                my_grade={item.my_grade}
                                my_grade_id={item.my_grade_id}
                                name={item.name}
                                name_id={item.name_id}
                                language={props.language}
                                target_grade={item.target_grade}
                                target_grade_id={item.target_grade_id}
                                nickName={camiApi?.data?.nickname}
                            />
                        ))}
                    </div>
                </div>
            </PageSection>
        </PageSizing>
    );
};

export default ChemiDetail;