import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BarcodeContents, BarcodeHeader } from "component/main/barcode";

const AddBarcode = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const barcodeSetType = state?.state?.barcodeSetType||null;
    
    const [popupData,setPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [btnChk,setBtnChk] =  useState(true);

    const [barcodeType,setBarcodeType] =  useState("");
    const [name,setName] =  useState("");
    const [nickname,setNickname] =  useState("");
    const [barcodeNumber,setBarcodeNumber] =  useState("");
    const [birth,setBirth] =  useState("");
    const [gender,setGender] =  useState("");

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const familyApi = useGet({
        url:localStorage.getItem("token") ? "/family" : null,
        loginType:"login"
    });
    const familyData = familyApi?.data?.my_info?.barcode_id;

    //바코드 등록
    function addBarcode(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("type", barcodeType);
        formData.append("barcode", `GEN${barcodeNumber}`);
        formData.append("gender", gender);
        formData.append("birthday", birth);
        if(barcodeType == "baby"){
            formData.append("baby_name", name);
            formData.append("baby_nickname", nickname);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/save`,
            loginType: "login",
            success: (data) => {
                setBtnChk(false);
                setPopupData({
                    addClass:null,
                    title:"Barcode Registration Successful.",
                    text:"Do you want to register additional barcode number?",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        navigate(-1);
                    },
                    btn0:"Close",
                    btn0Type:"line",
                    btn1:"Add",
                    btn1Type:"",
                    btnFunc1:()=>{
                        window.location.reload(true);
                    }
                });
                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    useEffect(() => {

    }, []);

    return (
        <PageSizing addClass="bottomBtn headerSet">
            <Header 
                pageClose={true}
                closeFunc={() => {navigate(-1)}}
                headTitle={null}
            />
            <div className="addBarcodeSection">
                <BarcodeHeader icon={true} title="<i>Please register the</i> <span>barcode number.</span>"/>
                <BarcodeContents
                    startScan={true}
                    typeChk={familyData}
                    barcodeSetType={barcodeSetType}
                    func={(settingData) => {
                        setBtnChk(settingData?.btnChk);
                        setBarcodeType(settingData?.barcodeType);
                        setName(settingData?.name);
                        setNickname(settingData?.nickname);
                        setBarcodeNumber(settingData?.barcodeNumber);
                        setBirth(settingData?.birth);
                        setGender(settingData?.gender);
                    }}
                    rightOpenFunc={(id,title) => {props.rightOpenFunc(id,title)}}
                />
            </div>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Register Barcode"}
                    disabled={btnChk}
                    func={() => {addBarcode()}}
                />
            </BtnBox>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default AddBarcode;