import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InspectionStatusItem } from "component/report/items";
import { SubLine } from "component/basic/tap";
import { PageSection, TextItem } from "component/app/items";

const InspectionStatus = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    
    const statusApi = useGet({
        url:`/barcode/history?barcode_id=${id}`,
        loginType:"login"
    });
    const statusData = statusApi?.data;
    const statusListData = statusApi?.data?.histories;

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing>
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Test Status"
                centerTitle={true}
            />
            <PageSection addClass="subPage">
                <div className="inspectionStatusInfo">
                    <h1 className="inspectionStatusInfo_title">Test Information</h1>
                    <TextItem title="Barcode Number" notranslate={true} contents={statusData?.barcode}/>
                    <TextItem title="Test Subject" notranslate={true} contents={`${statusData?.name}${statusData?.type == "baby" ? ` (${statusData?.type})` : ""}`}/>
                </div>
                <SubLine addClass="pageFull" margin="20px 0"/>
                <div className="inspectionStatusList">
                    {statusListData && statusListData?.map((item,i)=>(
                        <InspectionStatusItem
                            key={i}
                            addClass={i == 0 ? "active" : null}
                            name={item.test_status}
                            date={item.created_at}
                        />
                    ))}
                </div>
            </PageSection>
        </PageSizing>
    );
};

export default InspectionStatus;