import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem, BtnText } from "component/basic/btns";
import { ChkBox, DatePicer, InputItemBox, TextAreaItem } from "component/basic/formItems";
import { Popup, RightPopup, SelPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReturnContentsBox, ReturnSuccessItem, ReturnTextItem, ReturnTextItemBox, ReturnTitleBox } from "component/main/return";
import { OrderAddrAddr, OrderAddrInfo } from "component/product/detail";
import { comFormat, phoneChk, setDateTime, timeArr } from "js/function";

const Return = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const apiType = param.apiType;
    const type = param.type;

    const [btnChkEditAddr,setBtnChkEditAddr] =  useState(true);
    const [btnChk,setBtnChk] =  useState(true);

    const [popupData,setPopupData] =  useState(null);
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [settingName,setSettingName] = useState("");
    const [settingPhone,setSettingPhone] = useState("");
    const [settingProvince,setSettingProvince] = useState(null);
    const [settingCity,setSettingCity] = useState(null);
    const [settingDistrict,setSettingDistrict] = useState(null);
    const [settingsubdistrict,setSettingSubdistrict] = useState(null);
    const [settingAddrDetail,setSettingAddrDetail] = useState("");

    const [editName,setEditName] = useState("");
    const [editPhone,setEditPhone] = useState("");
    const [province,setProvince] = useState(null);
    const [city,setCity] = useState(null);
    const [district,setDistrict] = useState(null);
    const [subdistrict,setSubdistrict] = useState(null);
    const [editAddrDetail,setEditAddrDetail] = useState("");

    const [editPhoneErr,setEditPhoneErr] =  useState(null);

    const [provinceAddr,setProvinceAddr] = useState([]);
    const [cityAddr,setCityAddr] = useState([]);
    const [districtAddr,setDistrictAddr] = useState([]);
    const [subdistrictAddr,setSubdistrictAddr] = useState([]);

    const [provinceAddrText,setProvinceAddrText] = useState([]);
    const [cityAddrText,setCityAddrText] = useState([]);
    const [districtAddrText,setDistrictAddrText] = useState([]);
    const [subdistrictAddrText,setSubdistrictAddrText] = useState([]);

    const [basicAddrSet,setBasicAddrSet] = useState(false);

    const [returnQty,setReturnQty] = useState("");
    const [returnDate,setReturnDate] = useState("");
    const [parcelServiceName,setParcelServiceName] = useState("");
    const [transportNumber,setTransportNumber] = useState("");

    const [dateChk,setDateChk] = useState(false);

    //기본 배송지
    const deliveryApi = useGet({
        url: localStorage.getItem("token") ? "/mypage/delivery" : null,
        loginType:"login"
    });
    const deliveryData = deliveryApi?.data?.default_delivery;

    //주소 정보
    const provinceApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:"login"
    });
    const provinceData = provinceApi?.data?.types;

    const cityApi = useGet({
        url: province ? `/product/delivery/address?type=city&parent_id=${province.id}` : null,
        loginType:"login"
    });
    const cityData = cityApi?.data?.types||[];

    const districtApi = useGet({
        url: city ? `/product/delivery/address?type=district&parent_id=${city.id}` : null,
        loginType:"login"
    });
    const districtData = districtApi?.data?.types||[];

    const subdistrictApi = useGet({
        url: district ? `/product/delivery/address?type=subdistrict&parent_id=${district.id}` : null,
        loginType:"login"
    });
    const subdistrictData = subdistrictApi?.data?.types||[];

    //주소 팝업 오픈
    function addrPopupOpen(){
        setRightOpenType(true);
    }

    //반송
    function addReturn(){
        let formData = new FormData();
        formData.append("id", id);

        formData.append("return_qty", returnQty);
        formData.append("return_date", returnDate);
        formData.append("delivery_company", parcelServiceName);
        formData.append("delivery_number", transportNumber);

        formData.append("return_name", settingName);
        formData.append("return_phone", `+${settingPhone.replace(/\+/g,"")}`);
        formData.append("return_province", settingProvince?.text);
        formData.append("return_city", settingCity?.text);
        formData.append("return_district", settingDistrict?.text);
        formData.append("return_subdistrict", settingsubdistrict?.text);
        formData.append("return_address_detail", settingAddrDetail);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: apiType == "resend" ? `/barcode/resend/return` : `/barcode/return/v2`,
            loginType: "login",
            success: (data) => {
                navigate(`/return/${id}/${apiType}/success`);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //주소 변경 완료
    function editAddr(){
        setSettingName(editName);
        setSettingPhone(editPhone);
        setSettingProvince(province);
        setSettingCity(city);
        setSettingDistrict(district);
        setSettingSubdistrict(subdistrict);
        setSettingAddrDetail(editAddrDetail);

        setRightOpenType(false);
    }

    //배송지 세팅
    function addrSetting(){
        if(localStorage.getItem("token")){
            if(settingName){
                setEditName(settingName);
                setEditPhone(settingPhone);
                setProvince({id:settingProvince?.id,text:settingProvince?.text});
                setEditAddrDetail(settingAddrDetail);
                setTimeout(() => {
                    setCity({id:settingCity?.id,text:settingCity?.text});
                    setTimeout(() => {
                        setDistrict({id:settingDistrict?.id,text:settingDistrict?.text});
                        setTimeout(() => {
                            setSubdistrict({id:settingsubdistrict?.id,text:settingsubdistrict?.text});
                        }, 100);
                    }, 100);
                }, 100);
            }else{
                setEditName(settingName);
                setEditPhone(settingPhone);
                setProvince(null);
                setCity(null);
                setDistrict(null);
                setSubdistrict(null);
                setEditAddrDetail(settingAddrDetail);
            }
        }
    }

    useEffect(() => {
        if(localStorage.getItem("token") && deliveryData){
            setSettingName(deliveryData?.address_name);
            setSettingPhone(deliveryData?.address_phone);
            setSettingProvince({id:deliveryData?.province_id,text:deliveryData?.address_province});
            setSettingAddrDetail(deliveryData?.address_detail);
            setTimeout(() => {
                setSettingCity({id:deliveryData?.city_id,text:deliveryData?.address_city});
                setTimeout(() => {
                    setSettingDistrict({id:deliveryData?.district_id,text:deliveryData?.address_district});
                    setTimeout(() => {
                        setSettingSubdistrict({id:deliveryData?.sub_district_id,text:deliveryData?.address_subdistrict});
                    }, 100);
                }, 100);
            }, 100);

            setEditName(deliveryData?.address_name);
            setEditPhone(deliveryData?.address_phone);
            setProvince({id:deliveryData?.province_id,text:deliveryData?.address_province});
            setEditAddrDetail(deliveryData?.address_detail);
            setTimeout(() => {
                setCity({id:deliveryData?.city_id,text:deliveryData?.address_city});
                setTimeout(() => {
                    setDistrict({id:deliveryData?.district_id,text:deliveryData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:deliveryData?.sub_district_id,text:deliveryData?.address_subdistrict});
                    }, 100);
                }, 100);
            }, 100);
        }
    }, [deliveryData]);

    //주소 변경 이벤트
    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceData){
            provinceData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceData.length - 1 == i){
                    setProvinceAddr(setData);
                    setProvinceAddrText(setTextData);
                }
            });
        }else{
            setProvinceAddr(setData);
            setProvinceAddrText(setTextData);
        }
    }, [provinceData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(cityData){
            cityData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(cityData.length - 1 == i){
                    setCityAddr(setData);
                    setCityAddrText(setTextData);
                }
            });
        }else{
            setCityAddr(setData);
            setCityAddrText(setTextData);
        }
    }, [cityData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtData){
            districtData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtData.length - 1 == i){
                    setDistrictAddr(setData);
                    setDistrictAddrText(setTextData);
                }
            });
        }else{
            setDistrictAddr(setData);
            setDistrictAddrText(setTextData);
        }
    }, [districtData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictData){
                subdistrictData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictData.length - 1 == i){
                        setSubdistrictAddr(setData);
                        setSubdistrictAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictAddr(setData);
                setSubdistrictAddrText(setTextData);
            }
    }, [subdistrictData]);

    useEffect(() => {
        setCity(null);
        setDistrict(null);
        setSubdistrict(null);

        setCityAddr([]);
        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setCityAddrText([]);
        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [province]);

    useEffect(() => {
        setDistrict(null);
        setSubdistrict(null);

        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [city]);

    useEffect(() => {
        setSubdistrict(null);

        setSubdistrictAddr([]);

        setSubdistrictAddrText([]);
    }, [district]);

    useEffect(() => {
        if(editPhone !== "" || editPhone){
            if(phoneChk(editPhone)){
                setEditPhoneErr(null);
            }else{
                setEditPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setEditPhoneErr(null);
        }
    }, [editPhone]);

    //주소 버튼 유효성
    useEffect(() => {
        if(editName !== ""
        && phoneChk(editPhone)
        && province
        && city
        && district
        && subdistrict
        && editAddrDetail !== ""){
            setBtnChkEditAddr(false);
        }else{
            setBtnChkEditAddr(true);
        }
    }, [editName,editPhone,province,city,district,subdistrict,editAddrDetail]);

    //버튼 유효성
    useEffect(() => {
        if(settingName !== ""
        && phoneChk(settingPhone)
        && settingProvince
        && settingCity
        && settingDistrict
        && settingsubdistrict
        && settingAddrDetail !== ""
        && returnQty !== "" &&
        returnDate !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [returnQty,returnDate,parcelServiceName,transportNumber,settingName,settingPhone,settingProvince,settingCity,settingDistrict,settingsubdistrict,settingAddrDetail]);

    return (
        <PageSizing addClass={`${type == "add" ? "bottomBtnText headerSet" : "bottomBtn headerSet"}`}>
            <Header 
                pageClose={true}
                closeFunc={() => {navigate("/main")}}
                headTitle={type == "notice" ? "Return Check list" : type == "add" ? "Check Return Information" : "Return Information Successfully Registered"}
                centerTitle={true}
            />
            <div className="returnSection">
                <ReturnTitleBox
                    icon={true}
                    title={type == "notice" ? "Please double-check before returning!" : type == "add" ? "Please enter accurate return information." : "<span>Return information</span><i class='notranslate'> </i><i>has been successfully registered!</i>"}
                />
                {type == "notice" ? 
                    <ReturnTextItemBox>
                        <ReturnTextItem
                            text="Seal the small container"
                            caption="Be careful not to contaminate the cotton swab used for sample collection."
                        />
                        <ReturnTextItem
                            text="Place the kit in the ‘box’ for return."
                            caption="To prevent damage or opening of the kit, please return it in the same condition you received it (insert it into the plastic holder)."
                        />
                        <ReturnTextItem
                            text="<i>Return through a nearby courier and let us know your return information!</i>"
                            caption="<i>Click the [Confirmed] button below to go to the return information entry screen. To prevent the loss of your valuable DNA sample, please make sure to enter accurate information.</i>"
                        />
                    </ReturnTextItemBox>
                :""}
                {type !== "success" ? 
                <ReturnContentsBox
                    title={type == "add" ? "Return Information" : null}
                    func={()=>{addrPopupOpen()}}
                >
                    {
                        type == "notice" ? 
                        <div>
                            <h1 className="returnCs_title">Return Inquiry</h1>
                            <p className="returnCs_phone">+62 813-8855-2210</p>
                            <button type="button" onClick={()=>{navigate("/cs/faq")}} className="returnCs_btn">Go to Return FAQ</button>
                        </div>
                        : type == "add" ? 
                        <div>
                            <OrderAddrInfo info={`${settingName||""} ${settingPhone ? `(${settingPhone})` : ""}`}/>
                            <OrderAddrAddr addr={`${settingAddrDetail||""}${settingAddrDetail ? ", " : ""}${settingsubdistrict?.text||""}${settingsubdistrict?.text ? ", " : ""}${settingDistrict?.text||""}${settingDistrict?.text ? ", " : ""}${settingCity?.text||""}${settingCity?.text ? ", " : ""}${settingProvince?.text||""}`}/>
                        </div>
                        : 
                        ""
                    }
                </ReturnContentsBox>
                :""} 
                {type == "success" ? 
                <ReturnSuccessItem
                    info={`${settingName||""} ${settingPhone ? `(${settingPhone})` : ""}`}
                    addr={`${settingAddrDetail||""}${settingAddrDetail ? ", " : ""}${settingsubdistrict?.text||""}${settingsubdistrict?.text ? ", " : ""}${settingDistrict?.text||""}${settingDistrict?.text ? ", " : ""}${settingCity?.text||""}${settingCity?.text ? ", " : ""}${settingProvince?.text||""}`}
                    qty={comFormat(returnQty)}
                    date={returnDate}
                    parcelServiceName={parcelServiceName}
                    transportNumber={transportNumber}
                />
                :""}
                {type == "add" ? 
                <div>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="<i>Quantity Returned</i><i class='notranslate'> </i><span class='essential_icon'>*</span>"
                    placeholder="Enter Quantity"
                    value={returnQty}
                    max={null}
                    maxCount={50}
                    regexp={"countNumber"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    func={(value)=>{setReturnQty(value)}}
                />
                <DatePicer
                    inputName="<i>Return Date</i><i class='notranslate'> </i><span class='essential_icon'>*</span>"
                    placeholder={props.language == "en" ? "Select Date" : "Pilih Tanggal"}
                    value={returnDate}
                    dataChk={dateChk}
                    minCount={1}
                    func={(start) => {
                        setReturnDate(setDateTime(start,"yymmdd","hymd"));
                        setDateChk(!dateChk);
                    }}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Courier Used"
                    placeholder="Manually Enter Courier (ex. JNE)"
                    value={parcelServiceName}
                    max={null}
                    maxCount={null}
                    regexp={null}
                    inputMode={null}
                    pattern={null}
                    func={(value)=>{setParcelServiceName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Tracking Number"
                    placeholder="Tracking Number(ex. ab123456)"
                    value={transportNumber}
                    max={null}
                    maxCount={null}
                    regexp={null}
                    inputMode={null}
                    pattern={null}
                    func={(value)=>{setTransportNumber(value)}}
                />
                </div>:""}
            </div>
            <BtnBox
                boxType="fixed"
                addClass="btnTextAdd"
            >
                {type == "add" ? <BtnText text="<i>* To ensure your DNA is safely managed during delivery, please accurately provide the courier and tracking number.</i><i class='notranslate'> </i><b>Once entered, return information cannot be modified.</b>"/> : ""}
                <BtnItem
                    addClass=""
                    contents={type == "notice" ? "Yes, I have checked them" : type == "add" ? "Complete" : "Complete"}
                    disabled={type == "add" ? btnChk : false}
                    func={() => {
                        if(type == "notice"){
                            navigate(`/return/${id}/${apiType}/add`);
                        }else if(type == "add"){
                            addReturn();
                        }else{
                            navigate("/main");
                        }
                    }}
                />
            </BtnBox>
            <RightPopup
                topTitle={"Confirm Returner Information"}
                addClass={rightOpenType ? "active bottomBtnText sidePadding" : " bottomBtnText sidePadding"}
                closePopup={() => {addrSetting();setRightOpenType(false)}}
                openType={rightOpenType}
            >
                <ReturnTitleBox
                    icon={false}
                    title={"<i>Please enter the</i><i class='notranslate'> </i><span>sender information!</span>"}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name"
                    placeholder="Enter legal name"
                    value={editName}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={editPhone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={editPhoneErr}
                    func={(value)=>{setEditPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Province"
                    placeholder="Select Province"
                    value={province ? province?.text : ""}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Province",
                        addClass:null,
                        closeType:true,
                        items:provinceAddrText,
                        val:provinceAddr,
                        sel:province ? province?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setProvince({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="City"
                    placeholder="Select City"
                    value={city ? city?.text : ""}
                    readOnly={true}
                    disabled={province && cityAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select City",
                        addClass:null,
                        closeType:true,
                        items:cityAddrText,
                        val:cityAddr,
                        sel:city ? city?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCity({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="District"
                    placeholder="Select District"
                    value={district ? district?.text : ""}
                    readOnly={true}
                    disabled={city && districtAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select District",
                        addClass:null,
                        closeType:true,
                        items:districtAddrText,
                        val:districtAddr,
                        sel:district ? district?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setDistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Subdistrict"
                    placeholder="Select Subdistrict"
                    value={subdistrict ? subdistrict?.text : ""}
                    readOnly={true}
                    disabled={district && subdistrictAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Subdistrict",
                        addClass:null,
                        closeType:true,
                        items:subdistrictAddrText,
                        val:subdistrictAddr,
                        sel:subdistrict ? subdistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSubdistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Detailed Address"
                    placeholder="Detailed Address"
                    value={editAddrDetail}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditAddrDetail(value)}}
                />
                <ChkBox
                    addClass=""
                    func={(e) => setBasicAddrSet(e.target.checked)}
                    checkedType={basicAddrSet}
                    label={"Set as Default Shipping Address"}
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"Complete"}
                        disabled={btnChkEditAddr}
                        func={() => {editAddr()}}
                    />
                </BtnBox>
            </RightPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Return;