import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { MenuHeader, PageSizing } from "component/elements/header";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoryBox, CategoryDnaBox } from "component/basic/category";
import { HeaderTap } from "component/basic/tap";
import { CardItem, CardList, CardSection, FamilyList, ReportDataList, ReportDataListItem, ReportDataSection, ReportInfoSection, ReportTitleBox, UserReport } from "component/report/items";
import html2canvas from "html2canvas";
import { AlignBtnItem } from "component/basic/btns";
import { SelPopup } from "component/basic/popup";
import { nightTimeChk, scrollx_mouse } from "js/function";
import { useRef } from "react";

const Report = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const selBabyId = state?.state?.selBabyId ? state?.state?.selBabyId : sessionStorage.getItem("reportId") && sessionStorage.getItem("reportId") !== "null" && sessionStorage.getItem("reportId") !== "undefined" && sessionStorage.getItem("reportId") !== "0" ? sessionStorage.getItem("reportId") : null;
    const selUserName = state?.state?.selUserName ? state?.state?.selUserName : sessionStorage.getItem("reportId") && sessionStorage.getItem("reportId") !== "null" && sessionStorage.getItem("reportId") !== "undefined" && sessionStorage.getItem("reportId") !== "0" ? sessionStorage.getItem("reportName") : null;

    const [tapType,setTapType] = useState("report");
    const [barcode,setBarcode] = useState("");
    const [selName,setSelName] = useState("");
    const [align,setAlign] = useState("");
    const [alignText,setAlignText] = useState(props.language == "id" ? "Urutan Asli" : "Default Order");
    const [category,setCategory] = useState("");
    const [selPopupData,setSelPopupData] =  useState(null);

    const [viewChk,setViewChk] =  useState(false);

    const scrollItem = useRef(null);

    const familyApi = useGet({
        url: localStorage.getItem("token") ? `/family` : null,
        loginType: "login"
    });
    const familyData = familyApi?.data||null;

    const reportOptionApi = useGet({
        url: viewChk ? `/report/options?barcode_id=${barcode && barcode !== "null" ? barcode : ""}` : null,
        loginType: localStorage.getItem("token") ? "login" : null
    });
    const characterImg = nightTimeChk() ? 
    reportOptionApi?.data?.character_image_night ? reportOptionApi?.data?.character_image_night : "/assets/images/img/basic_profile.svg"
    : reportOptionApi?.data?.character_image ? reportOptionApi?.data?.character_image : "/assets/images/img/basic_profile.svg";
    const characterData = reportOptionApi?.data?.reports;
    const bestData = reportOptionApi?.data?.best_cards;
    const worstData = reportOptionApi?.data?.worst_cards;

    const categoryApi = useGet({
        url: localStorage.getItem("token") ? `/report/type` : null,
        loginType: "login"
    });
    const categoryData = categoryApi?.data?.types||null;

    const reportApi = useGet({
        url:localStorage.getItem("token") ? `/report?barcode_id=${barcode}&sort=${align}&type=${category}` : null,
        loginType: "login"
    });
    const reportData = reportApi?.data?.reports;

    const reportSampleData = [//리포트 샘플 데이터
        {
            type : "Exercise",
            gradeType : 1,
            name : "Weight Training Suitability",
            grade : "Medium",
            name_id : "Kebugaran stamina",
            grade_id : "Normal"
        },
        {
            type : "Health management",
            gradeType : 2,
            name : "Blood Sugar (Fasting)",
            grade : "Safe",
            name_id : "Gula darah (puasa)",
            grade_id : "Aman"
        },
        {
            type : "Hair/Skin",
            gradeType : 2,
            name : "Pigmentation",
            grade : "Safe",
            name_id : "Hiperpigmentasi",
            grade_id : "Aman"
        },
        {
            type : "Dietary habits",
            gradeType : 0,
            name : "Appetite",
            grade : "Warning",
            name_id : "Nafsu makan",
            grade_id : "Hati-hati"
        },
        {
            type : "Characteristic",
            gradeType : null,
            name : "Morning person/Night person",
            grade : "Morning person",
            name_id : "Tipe pagi/Tipe malam",
            grade_id : "Manusia Pagi"
        }
    ]

    function tapCh(id){
        if(id == "chemi"){
            navigate("/report/chemi");
        }
    }

    //가족 상태 별 이벤트
    function familyFunc(reportType,resultType,id,nickName,type,userType){
        if(reportType == 0){
            if(userType !== "spouse"){
                navigate(`/survey/${id}/${type}/basic`)
            }
        }else if(resultType == 0){
            navigate("/main",{state: {selUserId: id}})
        }else{
            setBarcode(id)
            setSelName(nickName)

            sessionStorage.setItem("reportId",id)
            sessionStorage.setItem("reportName",nickName)
        }
    }

    //리포트 저장
    function imageSave(url,name){
        let aTag = document.createElement("a");
        document.body.appendChild(aTag);
        aTag.href = url;
        aTag.download = name;
        aTag.click();
        document.body.removeChild(aTag);
    }

    function capture(){
        html2canvas(document.querySelectorAll(".captureBox")[0],{allowTaint: true,useCORS: true}).then(canvas=>{
            imageSave(canvas.toDataURL("image/png"),`my_report_image_${Math.floor(Math.random() * 101)}.png`)
        })
    }

    useEffect(()=>{
        if(props.language == "id"){
            if(alignText == "Default Order"){
                setAlignText('Urutan Asli')
            }else if(alignText == 'Order by "Safe"'){
                setAlignText('Urutan berdasarkan "Keamanan"')
            }else if(alignText == 'Order by "Warning"'){
                setAlignText('Urutan berdasarkan "Risiko"')
            }
        }else{
            if(alignText == "Urutan Asli"){
                setAlignText('Default Order')
            }else if(alignText == 'Urutan berdasarkan "Keamanan"'){
                setAlignText('Order by "Safe"')
            }else if(alignText == 'Urutan berdasarkan "Risiko"'){
                setAlignText('Order by "Warning"')
            }
        }
    },[props.language]);

    //마우스 좌우 스크롤
    useEffect(()=>{
        if(scrollItem.current){
            scrollx_mouse(scrollItem.current)
        }
    },[scrollItem.current]);

    useEffect(()=>{
        if(!localStorage.getItem("token")){
            setViewChk(true)
        }
    },[]);

    useEffect(()=>{
        let settingChk = true;
        let myBabyChk = false;
        let spouseBabyChk = false;

        if(selBabyId){
            setBarcode(selBabyId)
            setSelName(selUserName)

            sessionStorage.setItem("reportId",selBabyId)
            sessionStorage.setItem("reportName",selUserName)

            setViewChk(true);
        }else if(familyData?.my_info?.resultType == 1){
            setBarcode(familyData?.my_info?.barcode_id)
            setSelName(familyData?.my_info?.nickname)

            setViewChk(true);
        }else if(familyData?.my_baby_barcodes && familyData?.my_baby_barcodes.length > 0){
            familyData?.my_baby_barcodes.forEach(function(item,i){
                if(item.resultType == 1 && settingChk){
                    settingChk = false;
                    setBarcode(item?.barcode_id)
                    setSelName(item?.nickname)

                    setViewChk(true);
                }
                if(familyData?.my_baby_barcodes.length - 1 == i){
                    myBabyChk = true;
                    if(myBabyChk && spouseBabyChk){
                        setViewChk(true);
                    }
                }
            })
        }else if(familyData?.spouse_baby_barcodes && familyData?.spouse_baby_barcodes.length > 0){
            familyData?.spouse_baby_barcodes.forEach(function(item,i){
                if(item.resultType == 1 && settingChk){
                    settingChk = false;
                    setBarcode(item?.barcode_id)
                    setSelName(item?.nickname)

                    setViewChk(true);
                }
                if(familyData?.spouse_baby_barcodes.length - 1 == i){
                    spouseBabyChk = true;
                    if(myBabyChk && spouseBabyChk){
                        setViewChk(true);
                    }
                }
            })
        }else{
            setViewChk(true);
        }
    },[familyData]);

    return (
        <PageSizing addClass="headerPage">
            <MenuHeader
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
            />
            <HeaderTap
                data={[
                    {
                        id:"report",
                        category:"<img src='/assets/images/img/report_tap_on.svg'/><i>My DNA</i>"
                    },
                    {
                        id:"chemi",
                        category:"<img src='/assets/images/img/dna_tap_off.svg'/><i>Genetic Chemistry</i>"
                    }
                ]}
                val={tapType}
                func={(id) => {tapCh(id)}}
            />
            <ReportInfoSection color="#EFFDF5">
                {barcode !== "" ? 
                <div className="familyList">
                    {familyData?.my_info ? 
                        <div className="scrollCoverBox" ref={scrollItem}>
                        <FamilyList
                            activeChk={familyData?.my_info?.reportType == 0 || (familyData?.my_info?.reportType == 1 && familyData?.my_info?.resultType == 0) ? true : null}
                            id={familyData?.my_info?.barcode_id}
                            img={
                                nightTimeChk() ? familyData?.my_info?.character_image_night ? familyData?.my_info?.character_image_night : "/assets/images/img/basic_profile.svg"
                                 : familyData?.my_info?.character_image ? familyData?.my_info?.character_image : "/assets/images/img/basic_profile.svg"
                            }
                            caption="/assets/images/img/me_icon.svg"
                            name={familyData?.my_info?.reportType == 0 ? `<i>Start</i><i class="notranslate"> </i><i class="notranslate">${familyData?.my_info?.nickname}'s</i><i class="notranslate"> </i><i>basic survey</i>` : familyData?.my_info?.resultType == 0 ? `<i>Where is</i><br/><span class="notranslate">${familyData?.my_info?.nickname}</span><i>'s Baby Power?</i>` : familyData?.my_info?.nickname}
                            nameType={familyData?.my_info?.reportType == 0 ? null : familyData?.my_info?.resultType == 0 ? null : "normal"}
                            val={barcode}
                            notranslate={familyData?.my_info?.reportType !== 0 && familyData?.my_info?.resultType !== 0 ? true : false}
                            func={()=>{
                                familyFunc(familyData?.my_info?.reportType,familyData?.my_info?.resultType,familyData?.my_info?.barcode_id,familyData?.my_info?.nickname,"me");
                            }}
                        />
                
                        {familyData?.my_baby_barcodes && familyData?.my_baby_barcodes?.map((item,i)=>(
                            <FamilyList
                                key={i}
                                activeChk={item.reportType == 0 || (item.reportType == 1 && item.resultType == 0) ? true : null}
                                id={item.barcode_id}
                                img={
                                    nightTimeChk() ? item.character_image_night ? item.character_image_night : "/assets/images/img/basic_profile.svg"
                                    : item.character_image ? item.character_image : "/assets/images/img/basic_profile.svg"
                                }
                                caption={item.reportType == 0 ? "/assets/images/img/user_report.svg" : item.resultType == 0 ? "/assets/images/img/home_report.svg" : ""}
                                name={item.reportType == 0 ? `<i>Start</i><i class="notranslate"> </i><i class="notranslate">${item.nickname}'s</i><i class="notranslate"> </i><i>basic survey</i>` : item.resultType == 0 ? `<i>Where is</i><br/><span class="notranslate">${item.nickname}</span><i>'s Baby Power?</i>` : item.nickname}
                                nameType={item?.reportType == 0 ? null : item?.resultType == 0 ? null : "normal"}
                                val={barcode}
                                notranslate={item.reportType !== 0 && item.resultType !== 0 ? true : false}
                                func={()=>{
                                    familyFunc(item.reportType,item.resultType,item.barcode_id,item.nickname,"baby","my");
                                }}
                            />
                        ))}

                        {familyData?.spouse_baby_barcodes && familyData?.spouse_baby_barcodes?.map((item,i)=>(
                            <Fragment key={i}>
                            {item.is_failed == 1 ? "" :
                                <FamilyList
                                    key={i}
                                    activeChk={item.reportType == 0 || (item.reportType == 1 && item.resultType == 0) ? true : null}
                                    id={item.barcode_id}
                                    img={
                                        nightTimeChk() ? item.character_image_night ? item.character_image_night : "/assets/images/img/basic_profile.svg"
                                        : item.character_image ? item.character_image : "/assets/images/img/basic_profile.svg"
                                    }
                                    caption={item.reportType == 0 ? "/assets/images/img/user_report.svg" : item.resultType == 0 ? "/assets/images/img/home_report.svg" : ""}
                                    name={item.reportType == 0 ? `<i>Start</i><i class="notranslate"> </i><i class="notranslate">${item.nickname}'s</i><i class="notranslate"> </i><i>basic survey</i>` : item.resultType == 0 ? `<i>Where is</i><br/><span class="notranslate">${item.nickname}</span><i>'s Baby Power?</i>` : item.nickname}
                                    nameType={item?.reportType == 0 ? null : item?.resultType == 0 ? null : "normal"}
                                    val={barcode}
                                    notranslate={item.reportType !== 0 && item.resultType !== 0 ? true : false}
                                    func={()=>{
                                        familyFunc(item.reportType,item.resultType,item.barcode_id,item.nickname,"baby","spouse");
                                    }}
                                />
                            }
                            </Fragment>
                        ))}

                        <FamilyList
                            activeChk={true}
                            id={null}
                            img="/assets/images/img/addBarcode.svg"
                            caption=""
                            name="Add Baby"
                            nameType={"normal gColor"}
                            val={""}
                            func={()=>{navigate("/addBarcode")}}
                        />
                        </div>
                    :
                        <FamilyList
                            activeChk={true}
                            id={null}
                            img="/assets/images/img/addBarcode.svg"
                            caption=""
                            name="Register My Barcode"
                            nameType={"normal gColor"}
                            val={""}
                            func={()=>{navigate("/addBarcode")}}
                        />
                    }
                </div>
                :""}
                
                <UserReport
                    addClass=""
                    name={selName}
                    downloadType={false}
                    download={()=>{capture()}}
                    img={characterImg}
                    language={props.language}
                    sampleType={barcode == "" ? true : false}
                    characterData={characterData}
                />

                <div className="captureBox">
                    <UserReport
                        addClass=""
                        name={selName}
                        downloadType={true}
                        download={()=>{}}
                        language={props.language}
                        img={characterImg}
                        characterData={characterData}
                    />
                </div>
            </ReportInfoSection>
            {bestData && bestData.length > 0 ? 
            <CardSection>
                <ReportTitleBox
                    title="My Best Power Card"
                />
                <CardList>
                    {bestData?.map((item,i)=>(
                        <CardItem
                            key={i}
                            img={item.best_card_image}
                            text={props.language == "en" ? item.best_card_name : item.best_card_name_id ? item.best_card_name_id : item.best_card_name}
                            func={()=>{navigate(`/report/cardDetail/${barcode == "" ? "null" : barcode}/${item.id}/best`)}}
                        />
                    ))}
                </CardList>
            </CardSection>
            :""}
            {worstData && worstData.length > 0 ? 
            <CardSection>
                <ReportTitleBox
                    title="My Worst Power Card"
                />
                <CardList>
                    {worstData?.map((item,i)=>(
                        <CardItem
                            key={i}
                            img={item.worst_card_image}
                            text={props.language == "en" ? item.worst_card_name : item.worst_card_name_id ? item.worst_card_name_id : item.worst_card_name}
                            func={()=>{navigate(`/report/cardDetail/${barcode == "" ? "null" : barcode}/${item.id}/worst`)}}
                        />
                    ))}
                </CardList>
            </CardSection>
            :""}
            <ReportDataSection>
                <ReportTitleBox
                    title="Go to See Test Results"
                />
                {barcode !== "" ? 
                <div>
                <CategoryDnaBox
                    allBtn={true}
                    data={categoryData}
                    val={category}
                    func={(val) => {setCategory(val)}}
                />
                
                <div className="reportDataAlignBox">
                    <AlignBtnItem
                        addClass={"notranslate"}
                        contents={alignText}
                        img="/assets/images/basic/more_icon_b.svg"
                        func={() => {setSelPopupData({
                            title:"Sort",
                            addClass:null,
                            notranslate:true,
                            closeType:false,
                            items:props.language == "en" ? ['Default Order','Order by "Safe"','Order by "Warning"'] : ['Urutan Asli','Urutan berdasarkan "Keamanan"','Urutan berdasarkan "Risiko"'],
                            val:["","high","low"],
                            sel:alignText,
                            closeFunc:(e) => {},
                            func:(e) => {
                                setAlign(e.val);
                                setAlignText(e.text);
                            }
                        })}}
                    />
                </div>
                </div>
                :""}
                <ReportDataList>
                    {barcode !== "" ? 
                    <div>
                        {reportData && reportData?.map((item,i)=>(
                            <ReportDataListItem
                                key={i}
                                type={item.type}
                                gradeType={item.gradeType}
                                language={props.language}
                                func={() => {navigate(`/report/detail/${barcode}/${item.id}`)}}
                                name={item.name}
                                grade={item.grade}
                                name_id={item.name_id}
                                grade_id={item.grade_id}
                            />  
                        ))}
                    </div>
                    :
                    <div>
                    <div className="reportDataCover">
                        <img src="/assets/images/report/not_report.svg"/>
                        <p className="reportDataCover_text">This section cannot be viewed yet</p>
                    </div>
                    {reportSampleData?.map((item,i)=>(
                        <ReportDataListItem
                            key={i}
                            type={item.type}
                            gradeType={item.gradeType}
                            language={props.language}
                            func={() => {}}
                            name={item.name}
                            grade={item.grade}
                            name_id={item.name_id}
                            grade_id={item.grade_id}
                        />
                    ))}
                    </div>
                    }
                </ReportDataList>
            </ReportDataSection>
            <SelPopup
                data={selPopupData}
            />
        </PageSizing>
    );
};

export default Report;