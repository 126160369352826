import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { LoadingBox, Popup, RightPopup, SelPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BtnBox, BtnItem, MinBtnBtnItem } from "component/basic/btns";
import { FileItemMultiple, InputItemBox, TextAreaItem } from "component/basic/formItems";
import { ProductItem, ProductItemBox, ProductItemTitle } from "component/product/detail";
import { comFormat } from "js/function";
import { Html5QrcodePlugin } from "component/main/Html5QrcodePlugin";

const OrderCs = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;
    const id = param.id;
    const productId = param.productId;

    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [barcode,setBarcode] = useState([
        {
            id: 0,
            barcode: "",
            delType:false
        }
    ]);
    const [barcodeSelIndex,setBarcodeSelIndex] = useState(0);

    const [images,setImagse] = useState([]);
    
    const [qtyCount,setQtyCount] = useState([1]);
    const [qty,setQty] = useState(1);
    const [reasonType,setReasonType] = useState("");
    const [reasonText,setReasonText] = useState("");

    const [btnChk,setBtnChk] =  useState(true);

    //상품 정보
    const orderApi = useGet({
        url:`/order/product/detail?order_product_id=${productId}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.order_product;

    //교환/환불
    function addReturn(){
        let formData = new FormData();
        formData.append("order_product_id", productId);
        formData.append("type", type == "return" ? "Refund" : "Exchange");
        formData.append("reason", reasonType == "Etc" ? reasonText : reasonType);
        formData.append("qty", qty);

        barcode.forEach(function(item,i){
            if(item.barcode !== ""){
                formData.append(`barcodes[${i}]`, "GEN" + item.barcode);
            }
        });

        images.forEach(function(item,i){
            formData.append(`images[${i}]`, item);
        });
        
        setTimeout(() => {
            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/order/cancel`,
                loginType: "login",
                success: (data) => {
                    setPopupData({
                        addClass:null,
                        title:`${type == "return" ? "Refund" : "Exchange"} Successfully Applied`,
                        text: type == "return" ?
                        `Your refund application has been submitted. It may take up to 10 days for us to confirm your refund application. Depending on our refund policy, there's a possibility your request may be declined.`
                        : `Your application for exchange has been submitted. It may take up to 10 days for us to confirm your exchange application. Depending on our exchange policy, there's a possibility your request may be declined.`,
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            navigate(-1);
                        },
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }, 500);
    }

    //바코드 입력
    function onNewScanResult(decodedText, decodedResult){
        let barcodeList = [...barcode];
        if(decodedText.indexOf("GEN") !== -1){
            barcodeList[barcodeSelIndex].barcode = decodedText.replace(/GEN/g,"");
        }else{
            barcodeList[barcodeSelIndex].barcode = decodedText;
        }
        
        setBarcode(barcodeList);
        setRightOpenType(false);
    };

    //상품 수량 옵션 세팅
    useEffect(() => {
        if(orderData){
            let qtyData = [];
            for(var i = 1; i <= orderData?.qty; i++){
                qtyData.push(i);
                if(i == orderData?.qty){
                    setQtyCount(qtyData);
                }
            }
        }
    }, [orderApi]);

    //버튼 유효성
    useEffect(() => {
        if((reasonType == "The packaging is damaged."
        || reasonType == "The sample collection kit is damaged/contaminated."
        || reasonType == "There are missing items." ? images.length > 0 : true)
        && qty !== ""
        && ((reasonType !== "" && reasonType !== "Etc") || (reasonType == "Etc" && reasonText !== ""))){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [images,qty,reasonType,reasonText]);

    return (
        <PageSizing addClass="bottomBtn headerSet">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={`${type == "return" ? "Refund" : "Exchange"} request`}
                centerTitle={true}
            />
            <ProductItemBox>
                <ProductItemTitle
                    title={type == "return" ? "Refund Items" : "Exchange Requested Items"}
                />
                <ProductItem
                    img={orderData?.thumb_image}
                    name={props.language == "en" ? orderData?.product_name : orderData?.product_name_id}
                    options={orderData?.option_name ? `${orderData?.option_name} / ${orderData?.qty ? comFormat(orderData?.qty) : 1}EA` : `${orderData?.qty ? comFormat(orderData?.qty) : 1}EA`}
                    priceClass=""
                    sale_price={null}
                    price={orderData?.total_price}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={`${type == "return" ? "Refund" : "Exchange"} Quantity (Required)`}
                    placeholder={`Please select the quantity for ${type == "return" ? "refund" : "exchange"}`}
                    value={qty}
                    readOnly={true}
                    notranslate={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:`${type == "return" ? "Refund" : "Exchange"} Quantity`,
                        addClass:null,
                        notranslate:true,
                        closeType:true,
                        items:qtyCount,
                        val:qtyCount,
                        sel:qty,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setQty(e.text);
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={`Reason for ${type == "return" ? "refund" : "exchange"} (Required)`}
                    placeholder={`Select the reason for ${type == "return" ? "refund" : "exchange"}`}
                    value={reasonType}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:`Reason for ${type == "return" ? "refund" : "exchange"}`,
                        addClass:null,
                        closeType:true,
                        items: type == "return" ? 
                        ["Change of mind","Unauthorized purchase by friend or family member","I have not received the product yet","The product was delivered to a different address.","lineItem","The packaging is damaged.","The sample collection kit is damaged/contaminated.","There are missing items.","lineItem","Etc"]
                        :
                        ["The packaging is damaged.","The sample collection kit is damaged/contaminated.","There are missing items.","lineItem","Etc"],
                        val: type == "return" ? 
                        ["Change of mind","Unauthorized purchase by friend or family member","I have not received the product yet","The product was delivered to a different address.","lineItem","The packaging is damaged.","The sample collection kit is damaged/contaminated.","There are missing items.","lineItem","Etc"]
                        :
                        ["The packaging is damaged.","The sample collection kit is damaged/contaminated.","There are missing items.","lineItem","Etc"],
                        sel:reasonType,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setReasonType(e.text);
                        }
                    })}
                />                
            {reasonType == "Etc" ? 
                <TextAreaItem
                    addClass=""
                    inputName=""
                    placeholder={`Please enter your reason as detailed as possible.`}
                    max={500}
                    maxChk={true}
                    value={reasonText}
                    func={(e)=>setReasonText(e)}
                /> : ""}
                <FileItemMultiple
                    inputName={`Photos (${reasonType == "The packaging is damaged." || reasonType == "The sample collection kit is damaged/contaminated." || reasonType == "There are missing items." ? "Required, " : ""}Up to 5)`}
                    id="returnImages"
                    max={5}
                    maxSise={null}
                    dataReset={null}
                    func={(e)=>{
                        if(e){
                            let imgArr = [...e];
                            setImagse(imgArr);
                        }
                    }}
                />
                <div className="returnBarcode_section">
                    <div className="returnBarcode_titleBox">
                        <h1 className="returnBarcode_title">{type == "return" ? "Refund" : "Exchange"} Barcode Number (Optional)</h1>
                        <MinBtnBtnItem
                            addClass="nowrap"
                            contents="Add Barcode"
                            func={()=>{
                                let barcodeList = [...barcode];
                                barcodeList.push({
                                    id: barcodeList[barcodeList.length - 1].id + 1,
                                    barcode: "",
                                    delType:true
                                })
                                setBarcode(barcodeList);
                            }}
                        />
                    </div>
                    <p className="returnBarcode_titleCaption">Please enter the barcode numbers for the kit you want to {type == "return" ? "refund" : "exchange"}. You cannot enter a barcode number that has already been registered.</p>
                </div>
                <div className="returnBarcode_contents">
                    <img src="/assets/images/img/barcode_sample.png"/>
                    {/* //미비사항(이미지 미수령) */}
                    {
                        barcode.map((item,i)=>(
                            <div key={i} className={`returnBarcode_item ${item.delType ? "returnBarcode_itemDelBox" : ""}`}>
                                <InputItemBox
                                    inputType={null}
                                    addClass="barcodeNumberInput"
                                    inputName=""
                                    placeholder="Scan Barcode"
                                    value={
                                        barcode[i] ?
                                        barcode[i].barcode : ""
                                    }
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    max={11}
                                    regexp={"number"}
                                    func={(value)=>{
                                        let barcodeList = [...barcode];
                                        barcodeList[i].barcode = value;
                                        setBarcode(barcodeList);
                                    }}
                                >
                                    <p className="barcodeNumber_text notranslate">GEN</p>
                                    <button type="button" onClick={()=>{setRightOpenType(true);setBarcodeSelIndex(i)}} className="btnBarcodeNumber"><img src="/assets/images/icon/camera.svg"/></button>
                                </InputItemBox>
                                {item.delType ? 
                                    <button type="button" className="returnBarcode_itemDel" onClick={()=>{
                                        let barcodeList = [...barcode];
                                        setBarcode(barcodeList.filter((filterItem)=>filterItem.id !== item.id));
                                    }}><img src="/assets/images/basic/close.svg"/></button>
                                : ""}
                            </div>
                        ))
                    }
                </div>
            </ProductItemBox>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={`Request ${type == "return" ? "Refund" : "Exchange"}`}
                    disabled={btnChk}
                    func={() => {addReturn()}}
                />
            </BtnBox>
            <RightPopup
                topTitle={"Barcode Scanner"}
                bType={true}
                addClass={rightOpenType ? "active scanPopup" : " scanPopup"}
                closePopup={() => {setRightOpenType(false)}}
                openType={rightOpenType}
            >
                <div className="bacodeQr">
                    <div className="qrCover">
                        <p className="qrCoverText">Please place the kit so that the barcode is visible</p>
                        <p className="t"></p> 
                        <p className="l"></p>
                        <p className="c">
                            <span className="clt"></span>
                            <span className="crt"></span>
                            <span className="clb"></span>
                            <span className="crb"></span>
                        </p> 
                        <p className="r"></p> 
                        <p className="b"></p>  
                    </div>
                    <div className="qrBox">
                        {rightOpenType ? 
                        <Html5QrcodePlugin
                            setting={rightOpenType}
                            qrCodeSuccessCallback={onNewScanResult}
                            LoadingBoxOpen={(type)=>{
                                setLoadingBoxOpen(type);
                            }}
                            errPopup={()=>{
                                setPopupData({
                                    addClass:null,
                                    title:"No supported camera.",
                                    text:"There is currently no supported camera, or the camera cannot be loaded due to a temporary issue.",
                                    closeType:true,
                                    closeFunc:(e) => {},
                                    btnFunc0:(e)=>{
                                        setRightOpenType(false);
                                    },
                                    btn0:"Confirm",
                                    btn0Type:"",
                                    btn1:null,
                                    btn1Type:"",
                                    btnFunc1:()=>{}
                                });
                                setLoadingBoxOpen(false);
                            }}
                        />
                        :""}
                    </div>
                </div>
                <BtnBox
                    boxType="fixed"
                    addClass="notBg"
                >
                    <BtnItem
                        addClass="lineW"
                        contents={"Enter Barcode Directly"}
                        disabled={false}
                        func={() => {setRightOpenType(false)}}
                    />
                </BtnBox>
            </RightPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default OrderCs;