import { useEffect, useState } from "react";
import * as fatchSet from "../../api/api";
import { bodyScroll_clear, bodyScroll_limit, comFormat } from "js/function";
import { ChkBox } from "./formItems";

//우측 슬라이드 팝업
function RightPopup(data){
    const [popupOpen,setPopupOpen] =  useState("block");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.openType){
            setPopupData(data.openType)
            bodyScroll_limit();
        }else{
            setPopupData(null)
            bodyScroll_clear();
        }
    },[data.openType]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                // setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 200);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                // setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`rightPopup ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""} ${data.bType ? "bPopup" : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {setPopupData(null);data.closePopup && data.closePopup(e)}}></div>
            <div className="rightPopup_section">
                <div className="rightPopup_header_section">
                    {data.topTitle ? <h1 className="rightPopup_title" dangerouslySetInnerHTML={{__html:data.topTitle}}></h1> : ""}
                    <button type="button" className="btn_back rightPopupClose" onClick={(e) => {setPopupData(null);data.closePopup && data.closePopup(e)}}><img src={`/assets/images/basic/${data.bType ? "close_w.svg" : "close.svg"}`} alt="back_icon"/></button>
                </div>
                <div className="rightPopup_contents">
                    {data.children && data.children}
                </div>
            </div>
        </div>
    );
}

//로딩
function LoadingBox(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupClass,setPopupClass] =  useState(null);

    useEffect(()=>{
        if(data.openType){
            setPopupOpen("block");
            setPopupClass("active");
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setPopupOpen("none");
            bodyScroll_clear();
        }
    },[data.openType]);

    return (
        <div className={`loadingBox ${popupClass||""} ${data.addClass||""}`} style={{display:popupOpen}}>
            <div className="popup_cover"></div>
            <div id="loading" className="loading">
                <div className="loading_dotBox">
                    <div className="loading_dot loading_dot1"><i></i></div>
                    <div className="loading_dot loading_dot2"><i></i></div>
                    <div className="loading_dot loading_dot3"><i></i></div>
                    <div className="loading_dot loading_dot4"><i></i></div>
                    <div className="loading_dot loading_dot5"><i></i></div>
                    <div className="loading_dot loading_dot6"><i></i></div>
                    <div className="loading_dot loading_dot7"><i></i></div>
                    <div className="loading_dot loading_dot8"><i></i></div>
                    <div className="loading_dot loading_dot9"><i></i></div>
                </div>
            </div>
        </div>
    );
}

//하단 메세지 문구
let timeOut = "";
function BottomErrMsg(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.text && data.text !== ""){
            clearTimeout(timeOut);

            setPopupData(data.text);
            
            setPopupOpen("block");
            setTimeout(() => {
                setPopupClass("active");
            }, 100);

            timeOut = setTimeout(() => {
                setPopupClass("");
                setTimeout(() => {
                    setPopupOpen("none");
                }, 200);
            }, 2000);
        }
    },[data.chk]);

    return (
        <p className={`bottomErrMsg ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""}`} style={{display:popupOpen}} dangerouslySetInnerHTML={{__html:popupData}}></p>
    );
}

//기본팝업
function Popup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`popup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc && popupData?.closeFunc(e)}}/>
            <div className="popupSetion">
                <div className="popupTextBox">
                    {popupData?.title ? <h2 className="popupTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    <p className="popupText" dangerouslySetInnerHTML={{__html:popupData?.text}}/>
                </div>
                <div className={`popup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                    {popupData?.btn1 ? <button type="button" className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/> : ""}
                </div>
            </div>
        </div>
    );
}

//토스트팝업
function ToastBasicContents(data){
    return (
        <div className="toastBasicContents">
            {data.title ? <h1 className="toastBasicContents_title" dangerouslySetInnerHTML={{__html:data?.title}}/> : ""}
            {data.text ? <p className="toastBasicContents_text" dangerouslySetInnerHTML={{__html:data?.text}}/> : ""}
        </div>
    );
}

function ToastPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    const [chkVal,setChkVal] =  useState(popupData?.chkType||false);
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`toastPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}/>
            <div className="popupSetion">
                {popupData?.title || popupData?.closeFuncChk ?
                <div className="popupHaeder">
                    {popupData?.headBtn ? <button type="button" className="popupHeadBtn" onClick={(e) => {popupData?.headBtnFunc(e)}}>{popupData?.headBtn}</button> : ""}
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    {popupData?.closeFuncChk ? <button type="button" className="popupClose" onClick={(e) => {setPopupData(null);popupData?.closeFuncChk && popupData.closeFunc(e)}}><img src={`/assets/images/basic/close.svg`} alt="back_icon"/></button> : ""}
                </div> : ""}
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                {popupData?.btnChkBox ? 
                    <div className="popup_btnChkBox">
                        <ChkBox
                            addClass=""
                            func={(e) => {popupData?.btnChkFunc(e.target.checked);setChkVal(e.target.checked)}}
                            label={popupData?.btnChkFuncLabel}
                            chkFunc={(e) => popupData?.btnChkClickFunc()}
                            checkedType={popupData?.chkType||chkVal}
                        />
                    </div>
                :""}
                <div className={`popup_btnBox toastPopup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} disabled={popupData?.btn0Disabled ? popupData?.btn0Disabled : false} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                    {popupData?.btn1 ? <button type="button" disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/> : ""}
                </div>
            </div>
        </div>
    );
}

//선택팝업
function SelPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScroll_limit();
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScroll_clear();
        }
    },[popupData]);

    return (
        <div className={`selPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}></div>
            <div className="selPopupSetion">
                <div className="popupHaeder">
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    <button type="button" className="popupClose" onClick={(e) => {setPopupData(null);popupData?.closeFuncChk && popupData.closeFunc(e)}}><img src={`/assets/images/basic/close.svg`} alt="back_icon"/></button>
                </div>
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                <div className="selPopup_selBox yScroll">
                    {popupData?.dataKey ? 
                        popupData?.data?.length > 0 ?
                        popupData?.data.map((item, i) => (
                                <button type="button" key={i} className={`selPopup_sel ${popupData?.notranslate ? "notranslate" : ""} ${item[popupData?.dataKey] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:item.id, text:item[popupData?.dataKey]})}} dangerouslySetInnerHTML={{__html:item[popupData?.dataKey]}}></button>
                            ))
                            : ""
                        : 
                        popupData?.items?.map((item, i) => (
                            popupData?.val[i] == "notSel" ?
                            <p key={i} className="selPopup_selSub_category" dangerouslySetInnerHTML={{__html:item}}></p>
                            :
                            popupData?.val[i] == "lineItem" ?
                            <p key={i} className="selPopup_selSub_lineItem"></p>
                            :
                            <button type="button" key={i} className={`selPopup_sel ${popupData?.notranslate ? "notranslate" : ""} ${item == popupData?.sel || popupData?.val[i] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:popupData?.val[i], text:item})}} dangerouslySetInnerHTML={{__html:item}}></button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export {Popup, ToastPopup, SelPopup, LoadingBox, RightPopup, BottomErrMsg, ToastBasicContents}