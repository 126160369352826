import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputBoxOnly, InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup, ToastPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { birthSetting, phoneChk } from "js/function";
import { InputTap } from "component/basic/tap";

const Join = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const joinType = param.type;
    const marketingType = param.marketingType;

    const emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;
    const pwRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

    const [joinPage,setJoinPage] =  useState(joinType == "sns" ? 2 : 0);
    const [popupData,setPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [email,setEmail] =  useState("");

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);

    const [name,setName] =  useState("");
    const [nickName,setNickName] =  useState("");
    const [phone,setPhone] =  useState("");
    const [gender,setGender] =  useState("");
    const [birthday,setBirthday] =  useState("");

    const [phoneErr,setPhoneErr] =  useState(null);

    const [phoneCetified,setPhoneCetified] =  useState("");
    const [phoneCertifyErr,setPhoneCertifyErr] =  useState(null);
    const [phoneTimerCount,setPhoneTimerCount] =  useState("3:00");
    const [phoneCetifiedChk,setPhoneCetifiedChk] =  useState(false);
    const [phoneCetifiedComChk,setPhoneCetifiedComChk] =  useState(false);
    const [phoneCetifiedInputComChk,setPhoneCetifiedInputComChk] =  useState(false);
    
    const [clickChk,setClickChk] =  useState(true);

    //이메일 인증
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if(sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("The authentication time has expired.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(true);
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        if(clickChk){
            setClickChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("email", email);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/email/certification`,
                success: (data) => {
                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);
                    setClickChk(true); 
                },
                err: (data) => {
                    setClickChk(true); 
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:null,
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function cetifiedEnd(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("email", email);
        formData.append("code", cetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/email/verification`,
            success: (data) => {
                setBtnChk(false);
                setCetifiedComChk(true);

                setTimerCount("3:00");
                setCertifyErr("");

                clearInterval(sval.current);
                sval.current = null;

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert == "An account is already registered with the email you entered."){
                    setPopupData({
                        addClass:null,
                        title:"This email address has already been registered.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }else if(data.alert){
                    setCertifyErr(data.alert);
                }
                setBtnChk(true);
                setLoadingBoxOpen(false);
            }
        })
    }

    //휴대폰 인증
    const phoneSval = useRef(null);
    function phoneTimerStart(){
        //타이머
        setPhoneTimerCount("3:00");
        setPhoneCertifyErr("");
        
        if (phoneSval.current !== null) {
            clearInterval(phoneSval.current);
            phoneSval.current = null;
        }
        
        phoneTimerChk(2.99);

        function phoneTimerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            phoneSval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setPhoneTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(phoneSval.current);
                    phoneSval.current = null;
                    setPhoneCertifyErr("The authentication time has expired.");

                    setPhoneCetifiedComChk(true);
                    setPhoneCetified("");
                }
            }, 1000);
        }
    }

    function phoneCetifiedStert(){
        if(clickChk){
            setClickChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/phone/certification`,
                success: (data) => {
                    phoneTimerStart();
                    setPhoneCetified("");
                    setPhoneCetifiedChk(true);

                    setLoadingBoxOpen(false);
                    setClickChk(true);
                },
                err: (data) => {
                    setClickChk(true);
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }

                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function phoneCetifiedEnd(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("code", phoneCetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/phone/verification`,
            success: (data) => {
                setPhoneCetifiedComChk(true);
                setPhoneCetifiedInputComChk(true);

                setPhoneTimerCount("3:00");
                setPhoneCertifyErr("");

                clearInterval(phoneSval.current);
                phoneSval.current = null;

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    if(data.alert == "Please enter a different Mobile Phone Number."){
                        setPhoneCetified("");
                        setPhoneCetifiedChk(false);
                        setPhoneTimerCount("3:00");
                        setPhoneCertifyErr("");

                        clearInterval(phoneSval.current);
                        phoneSval.current = null;

                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:"Please enter a different Mobile Phone Number.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert == "An account is already registered with the Mobile Phone Number you entered."){
                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert){
                        setPhoneCertifyErr(data.alert);
                    }
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //다음 페이지, 완료
    function joinNext(){
        if(joinPage == 0 || joinPage == 1){
            setBtnChk(true);
            setPwErr(null)
            setPwComErr(null)
            
            setJoinPage(joinType == "apple" ? 2 : joinPage+1);
        }else{
            let formData = new FormData();
            if(joinType == "email"){
                formData.append("email", email);
                formData.append("password", pw);
            }

            if(joinType == "apple"){
                formData.append("email", email);
            }

            formData.append("name", name);
            formData.append("nickname", nickName);
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);

            formData.append("gender", gender == "M" ? "Male" : "Female");
            formData.append("birthday", birthSetting(birthday));

            formData.append("is_marketing", marketingType);

            formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: joinType == "sns" || joinType == "apple" ? `/join/add` : `/join`,
                loginType: joinType == "sns" || joinType == "apple" ? "snsLogin" : null,
                success: (data) => {
                    if(joinType == "sns" || joinType == "apple"){
                        localStorage.setItem("token", sessionStorage.getItem("token"));
                        localStorage.setItem("refresh_token", sessionStorage.getItem("refresh_token"));
                    }else{
                        localStorage.setItem("token",data.data.token.access_token);
                        localStorage.setItem("refresh_token",data.data.token.refresh_token);
                    }

                    localStorage.removeItem("nonmemberKey");

                    setToastPopupData({
                        addClass:null,
                        title:"Registration Complete",
                        closeType:true,
                        closeFuncChk:true,
                        closeFunc:(e) => {
                            props.logIn();

                            if(sessionStorage.getItem("setUrl")){
                                let reUrl = sessionStorage.getItem("setUrl");
                                sessionStorage.removeItem("setUrl");
                                navigate(reUrl);
                            }else{
                                navigate(`/main`);
                            }
                            
                            setTimeout(() => {
                                props.logIn();

                                if(sessionStorage.getItem("setUrl")){
                                    let reUrl = sessionStorage.getItem("setUrl");
                                    sessionStorage.removeItem("setUrl");
                                    navigate(reUrl);
                                }else{
                                    navigate(`/main`);
                                }
                            }, 20000);
                        },
                        btnFunc0:(e)=>{
                            props.logIn();

                            if(sessionStorage.getItem("setUrl")){
                                let reUrl = sessionStorage.getItem("setUrl");
                                sessionStorage.removeItem("setUrl");
                                navigate(reUrl);
                            }else{
                                navigate(`/main`);
                            }
                        },
                        btn0:"<i>Start with</i><i class='notranslate'> BabyDNA</i>",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:null,
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {
                                setTimeout(() => {
                                    setBtnChk(false);
                                }, 500);
                            },
                            btnFunc0:(e)=>{
                                setTimeout(() => {
                                    setBtnChk(false);
                                }, 500);
                            },
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else{
                        setTimeout(() => {
                            setBtnChk(false);
                        }, 500);
                    }
                }
            })
        }
    }
    
    //뒤로가기(물리버튼)
    function joinBack(){
        setPopupData({
            addClass:null,
            title:"Shall we do it later?",
            text:"<i>If you leave now, you'll have to start over.</i><br/><span class='rColor'>* All entered information will be deleted.</span>",
            closeType:true,
            closeFunc:(e) => {},
            btn0:"Cancel",
            btn0Type:"line",
            btnFunc0:(e)=>{},
            btn1:"Confirm",
            btn1Type:"",
            btnFunc1:()=>{navigate(-3)}
        });
    }

    function backEvent(){
        window.history.pushState(null, '', window.location.href);
        joinBack();
    }
     
    useEffect(() => {
         window.history.pushState(null, '', window.location.href)
         window.addEventListener('popstate', backEvent);
         return () => {
           window.removeEventListener('popstate', backEvent);
         }
    }, [])

    //버튼 유효성
    useEffect(() => {
        if(pw !== pwCom && pw !== "" && pwCom !== ""){
            setPwComErr("Passwords do not match.")
        }else{
            setPwComErr(null)
        }

        if(!pwRegExp.test(pw) && pw !== ""){
            setPwErr("Please enter a combination of English letters, numbers, and special characters, ranging from 8 to 20 characters.")
        }else{
            setPwErr(null)
        }

        if(pw == pwCom && pwRegExp.test(pw)){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [pw,pwCom]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    //버튼 유효성
    useEffect(() => {
        let regex = RegExp(/^(19[0-9]{2}|20[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);

        if(name !== "" && nickName !== "" && phoneChk(phone) && phoneCetifiedComChk && regex.test(birthday) && gender !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [name,nickName,phone,phoneCetifiedComChk,birthday,gender]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {joinBack()}}
                headTitle={joinPage == 0 ? "Email Verification" : joinPage == 1 ? "Set Password" : "Enter Member Information"}
                centerTitle={true}
            />
            <MemberPage>
                <MemberTitle
                    contents={joinPage == 0 ? "<span>To receive the verification code,</span><br/><i>we need your email address.</i>"
                    : joinPage == 1 ? "<i>Please set a </i><br/><span>password</span><i> for login.</i>"
                    : "<i>Finally, please enter your </i><span>member information.</span>"}
                />
                <MemberInputSection>
                        {
                        joinPage == 0 ? 
                        <div>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="Email Address"
                                placeholder="example@email.com"
                                value={email}
                                max={null}
                                regexp={null}
                                func={(value)=>{setEmail(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                                btnAddClass={`${cetifiedChk || emailRegExp.test(email) ? "pBg" : ""}`}
                                btnDisabled={!emailRegExp.test(email)}
                                clickEv={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                                btnName={`${cetifiedChk ? "Resend" : "Verify"}`}
                                caption="Please authenticate with the email you check most frequently. Important notifications from BabyDNA, such as orders, completion of genetics tests, and adding of partner, will be sent to this email."
                            />
                            {cetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="Verify"
                                placeholder=""
                                value={cetified}
                                max={6}
                                readOnly={cetifiedComChk}
                                cetified={timerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCetified(value)}}
                                btnAddClass={`${cetified.length == 6 && !cetifiedComChk ? "pBg" : ""}`}
                                btnDisabled={cetifiedComChk || cetified.length !== 6}
                                clickEv={()=>{cetifiedEnd()}}
                                btnName={cetifiedComChk ? "Verified" : "Verify"}
                                errMsg={certifyErr}
                                caption="<i>Enter Verification Code</i><br/><i>If you haven't received the email, check your spam folder.</i>"
                            />
                            :""}
                        </div>
                        :""}
                        {joinPage == 1 ? 
                        <div>
                            <InputItemBox
                                inputType="password"
                                addClass=""
                                inputName="Password"
                                placeholder="********"
                                value={pw}
                                max={20}
                                regexp={null}
                                errMsg={pwErr}
                                func={(value)=>{setPw(value)}}
                                caption="8 to 20 characters containing a combination of English letters, numbers, and special characters"
                            />
                            <InputItemBox
                                inputType="password"
                                addClass=""
                                inputName="Confirm Password"
                                placeholder="Re-enter Password"
                                value={pwCom}
                                max={20}
                                regexp={null}
                                errMsg={pwComErr}
                                func={(value)=>{setPwCom(value)}}
                            />
                        </div>
                        :""}
                        {joinPage == 2 ? 
                        <div>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="Name"
                                placeholder="Enter legal name"
                                value={name}
                                max={255}
                                regexp={null}
                                func={(value)=>{setName(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="Date of Birth"
                                placeholder="YYYY-MM-DD"
                                value={birthday}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                birthSetting={true}
                                max={10}
                                regexp={null}
                                func={(value)=>{setBirthday(value)}}
                            />
                            <InputBoxOnly addClass="" inputName="Genetic Gender">
                                <InputTap
                                    data={[
                                        {
                                            id:"M",
                                            name:"Male"
                                        },
                                        {
                                            id:"F",
                                            name:"Female"
                                        }
                                    ]}
                                    val={gender}
                                    func={(value) => {setGender(value)}}
                                />
                            </InputBoxOnly>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="Nickname"
                                placeholder=""
                                value={nickName}
                                max={14}
                                regexp={null}
                                func={(value)=>{setNickName(value)}}
                                caption="Enter Nickname (max 14 chars)"
                            />

                            <InputItemBox
                                inputType={null}
                                addClass="inputSubCaptionItem"
                                inputName="Mobile Phone Number"
                                placeholder="6201234567890"
                                value={phone}
                                readOnly={phoneCetifiedInputComChk}
                                max={16}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                errMsg={phoneErr}
                                func={(value)=>{setPhone(value);setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetified("")}}
                                btnAddClass={phoneChk(phone) ? `pBg`: ""}
                                btnDisabled={!phoneChk(phone)}
                                clickEv={()=>{
                                    if(phoneCetifiedComChk && phoneCetified !== ""){
                                        setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetifiedInputComChk(false);setPhoneCetified("");
                                    }else{
                                        phoneCetifiedStert();setPhoneCetifiedComChk(false);setPhoneCetified("");
                                    }
                                }}
                                btnName={`${phoneCetifiedComChk && phoneCetified !== "" ? "Change" : phoneCetifiedChk ? "Resend" : "Verify"}`}
                            >
                                <p className="inputSubCaption_text">+</p>
                            </InputItemBox>
                            {phoneCetifiedChk ? 
                                <InputItemBox
                                    inputType={null}
                                    addClass="cetifiedInput"
                                    inputName="Verify"
                                    placeholder=""
                                    value={phoneCetified}
                                    max={6}
                                    readOnly={phoneCetifiedComChk}
                                    cetified={phoneTimerCount}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setPhoneCetified(value)}}
                                    btnAddClass={`${phoneCetified.length == 6 && !phoneCetifiedComChk ? "pBg" : ""}`}
                                    btnDisabled={phoneCetifiedComChk || phoneCetified.length !== 6}
                                    clickEv={()=>{phoneCetifiedEnd()}}
                                    btnName={phoneCetifiedComChk ? "Verified" : "Verify"}
                                    errMsg={phoneCertifyErr}
                                    caption="Enter Verification Code."
                                />
                            :""}
                        </div>
                        : ""
                        }
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={joinPage == 0 ? "Verified" : joinPage == 1 ? "Next" : "Complete"}
                        disabled={btnChk}
                        func={() => {setBtnChk(true);joinNext()}}
                    />
                </BtnBox>
            </MemberPage>
            <Popup
                data={popupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <img src="/assets/images/sns/whatsapp.svg" className="whatsPopupImg"/>
                <h1 className="whatsPopupTitle">Nice to meet you!</h1>
                <p className="whatsPopupText">
                    <i>Important notifications are sent to the phone number you have entered during sign up. Make sure you have entered the right phone number!</i>
                    <br/><br/>
                    <i>* Coming soon</i>
                </p>
            </ToastPopup>
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Join;