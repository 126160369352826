//tap item
function Tap(data){
    return (
        <div className={`tapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`tapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

//header 하단 탭
function HeaderTap(data){
    return (
        <div className={`headerTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`headerTapItem ${item.id == data.val ? "active" : ""}`} dangerouslySetInnerHTML={{__html:item.category}}/>
            ))}
        </div>
    );
}

//다중 값 선택
function InputTap(data){
    return (
        <div className={`inputTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`inputTapItem ${item.id == data.val ? "active" : ""} ${data.notranslate ? "notranslate" : ""}`}>{item.name}</button>
            ))}
        </div>
    );
}

//컨텐츠 사이 border
function SubLine(data){
    return (
        <div className={`subLine ${data.addClass ? data.addClass : ""}`} style={{margin:data.margin}}></div>
    );
}

export {Tap,HeaderTap, InputTap, SubLine}