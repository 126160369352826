import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { MenuBox, MenuItem, MenuTitle } from "component/menu/menuItems";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";

const Setting = (props) => {
    const navigate = useNavigate();

    const [errPopupData,setErrPopupData] =  useState(null);

    const [spouseAlarm,setSpouseAlarm] = useState(false);
    const [marketing,setMarketing] = useState(false);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const settingApi = useGet({
        url:"/mypage/setting",
        loginType:"login"
    });
    const settingData = settingApi?.data;

    //약관 동의 값 변경
    function settingCh(type){
        let formData = new FormData();
        formData.append("category", type);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/mypage/setting/modify",
            success: (data) => {
                
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        setSpouseAlarm(settingData?.spouse_alarm == 1 ? true : false);
        setMarketing(settingData?.is_marketing == 1 ? true : false);
    }, [settingData]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Settings"
                centerTitle={true}
            />
            <MypageSection addClass="notSide">
                <MenuBox>
                    <MenuTitle text="General"/>
                    <MenuItem
                        addClass=""
                        menuItemName="Terms of Service"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{props.rightOpenFunc(1,"Terms of Service")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Privacy Policy"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{props.rightOpenFunc(2,"Privacy Policy")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Cookie Policy"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{props.rightOpenFunc(4,"Cookie Policy")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move"
                        toggleBtn={false}
                    />
                </MenuBox>
                <MenuBox>
                <MenuTitle text="Notifications"/>
                    <MenuItem
                        addClass="disabled"
                        menuItemName="Order/Testing Status Notification"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{
                            setBottomMsgData({
                                addClass : "",
                                text : "Order/Testing Status Notifications are essential for using the service.",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                        cursorType="auto"
                        subItemText={null}
                        subItemTextAddClass=""
                        icon={null}
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Receive Genetic Chemistry Invitation"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon={null}
                        toggleBtn={true}
                        checkedType={spouseAlarm}
                        chkFunc={(e)=>{setSpouseAlarm(e);settingCh("spouse_alarm")}}
                        toggleId="spouseAlarm"
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Receive Marketing and Promotion Information"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon={null}
                        toggleBtn={true}
                        checkedType={marketing}
                        chkFunc={(e)=>{setMarketing(e);settingCh("is_marketing")}}
                        toggleId="marketing"
                    />
                </MenuBox>
                <MenuBox>
                <MenuTitle text="Account"/>
                    {settingData?.join_type == "basic" || !settingData?.join_type ? 
                        <MenuItem
                            addClass=""
                            menuItemName="Set Password"
                            nameIcon={null}
                            disabled={false}
                            func={()=>{navigate("/mypage/pwEdit")}}
                            subItemText={null}
                            subItemTextAddClass=""
                            icon="move"
                            toggleBtn={false}
                        />
                    :""}
                    <MenuItem
                        addClass=""
                        menuItemName="Logout"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{props.logOut();navigate("/")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Withdrawal"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{navigate("/mypage/withdrawal")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move"
                        toggleBtn={false}
                    />
                </MenuBox>
            </MypageSection>
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={errPopupData}
            />
        </PageSizing>
    );
};

export default Setting;