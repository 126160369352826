import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox } from "component/basic/formItems";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Terms = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const chkData = [{
        id:1,
        term_title:"[Required] Terms of Service",
        func:true
    },
    {
        id:2,
        term_title:"[Required] Privacy Policy",
        func:true
    },
    {
        id:3,
        term_title:"[Required] Policy on Provision of Personal Information to Third-Party",
        func:true
    },
    {
        id:"not",
        term_title:"[Required] I consent to the collection, use and disclosure of my personal data in accordance with Genoscape's Terms of Use and Privacy Policy.",
        func:false
    },
    {
        id:"marketing",
        term_title:"[Optional] I consent to receiving marketing information in accordance with Genoscape’s Privacy Policy.",
        func:false
    }];

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [btnChk,setBtnChk] =  useState(true);
    const [terms,setTerms] =  useState("");
    const [rightOpenType,setRightOpenType] =  useState(false);
    
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
        chkData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const joinStert = () => {    
        navigate(`/member/join/${type}/${checkItems.includes("marketing") ? 1 : 0}`);
    }

    //버튼 유효성
    useEffect(() => {
        let chkSet = [...checkItems];
        if(chkSet.filter((el) => el !== "marketing")?.length == chkData?.length - 1){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);
     
    useEffect(() => {
         
    }, [])

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Agreement to Terms of Membership"
                centerTitle={true}
            />
            <MemberPage addClass="bottomContents">
                <MemberTitle
                    contents="<i>Please agree to the terms and conditions</i><br/><span> to sign up.</span>"
                />
                <MemberInputSection>
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems?.length === chkData?.length ? true : false}
                        // chkSubType=""
                        label="Agree to All"

                    />
                    {chkData?.map((item, i) => (
                        <ChkBox
                            key={i}
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,item.id,item.term_title)}
                            checkedType={checkItems.includes(item.id) ? true : false}
                            label={item.term_title}
                            chkFunc={item.func ? (e) => props.rightOpenFunc(item.id,item.term_title) : null}
                        />
                    ))}
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="Next"
                        disabled={btnChk}
                        func={() => {joinStert()}}
                    />
                </BtnBox>
            </MemberPage>
        </PageSizing>
    );
};

export default Terms;