import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BgSection } from "component/app/items";
import { CategoryBox } from "component/basic/category";
import { AccordionItem, AccordionSection } from "component/basic/items";
import { NotItems } from "component/basic/notItems";
import { zendeskOpen } from "js/function";

const Faq = (props) => {
    const navigate = useNavigate();

    const [id,setId] = useState("");
    const [faqCloseChk,setFaqCloseChk] = useState("");

    const faqCategoryApi = useGet({
        url:`/faq/category`,
        loginType: localStorage.getItem("token") ? "login" : null
    });
    const faqCategoryData = faqCategoryApi?.data?.faq_categories;
    const faqApi = useGet({
        url:`/faq?category_id=${id}`,
        loginType: localStorage.getItem("token") ? "login" : null
    });
    const faqData = faqApi?.data?.faqs;

    useEffect(()=>{
        if(id == ""){
            setId(faqCategoryData ? faqCategoryData[0]?.id : "");
        }
    },[faqCategoryData])

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Customer Service"
                centerTitle={true}
            />
            <BgSection>
                <CategoryBox
                    data={faqCategoryData}
                    val={id}
                    func={(val) => {setId(val)}}
                />
                <AccordionSection>
                    {
                    faqData && faqData?.length > 0 ?
                    faqData.map((item,i)=>(
                        <AccordionItem
                            id={i}
                            key={i}
                            closeChk={faqCloseChk}
                            title={props.language == "en" ? item.question : item.question_id}
                            text={props.language == "en" ? item.answer : item.answer_id}
                            func={(id)=>{
                                setFaqCloseChk(id)
                            }}
                        />
                    ))
                    :
                    <NotItems
                        addClass="pageType categoryType"
                        img="/assets/images/icon/notFaq.svg"
                        text="No FAQs are registered."
                    />
                    }
                </AccordionSection>
            </BgSection>
            <button type="button" onClick={()=>{
                zendeskOpen();
            }} className="btn_zendesk">
                <p className="btn_zendeskText">1:1 Inquiry</p>
                <img src="/assets/images/icon/cs_icon.svg"/>
            </button>
        </PageSizing>
    );
};

export default Faq;