import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup, SelPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { BtnBox, BtnItem, BtnSubBox } from "component/basic/btns";
import { ChkBox, InputItemBox, TextAreaItem } from "component/basic/formItems";

const Withdrawal = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);

    const [withdrawalType,setWithdrawalType] = useState("");
    const [withdrawalText,setWithdrawalText] = useState("");

    const [chkVal,setChkVal] =  useState(false);

    const [btnChk,setBtnChk] = useState(true);

    //회원 탈퇴
    function withdrawalStart(){
        let formData = new FormData();
        formData.append("reason", withdrawalType == "Etc" ? withdrawalText : withdrawalType);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/mypage/withdrawal",
            success: (data) => {
                sessionStorage.setItem("withdrawal",true);
                
                props.logOut();
                navigate("/main");
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //버튼 유효성
    useEffect(() => {
        if(((withdrawalType !== "" && withdrawalType !== "Etc") || (withdrawalType == "Etc" && withdrawalText !== "")) && chkVal){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [withdrawalType,withdrawalText,chkVal]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageClose={true}
                closeFunc={() => {navigate(-1)}}
                headTitle="Withdrawal"
                centerTitle={true}
            />
            <MypageSection addClass="bottomBtn">
                <div className="withdrawal_textBox">
                    <img src="/assets/images/icon/withdrawal.svg"/>
                    <p className="withdrawal_text"><i>You cannot rejoin within 24 hours after your withdrawal.</i><br/>
                    <span>Personal identification information, sensitive information,</span><br/><i> and </i><span>test results</span><i> will be deleted.</i><br/>
                    <i>Previously registered barcodes cannot be registered again.</i></p>
                </div>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Reason for Withdrawal"
                    placeholder="Please select the reason for withdrawal."
                    value={withdrawalType}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Reason for Withdrawal",
                        addClass:null,
                        closeType:true,
                        items:["I want to delete my records (personal information).","I don't trust the service.","It's inconvenient to use.","I have complaints about customer service.","The product prices are too high.","I will no longer use it.","I don't like the content.","Family relationships have changed.","Notifications come too often.","I don't use it frequently.","I want to rejoin after withdrawal.","There are no desired products.","Etc"],
                        val:["I want to delete my records (personal information).","I don't trust the service.","It's inconvenient to use.","I have complaints about customer service.","The product prices are too high.","I will no longer use it.","I don't like the content.","Family relationships have changed.","Notifications come too often.","I don't use it frequently.","I want to rejoin after withdrawal.","There are no desired products.","Etc"],
                        sel:withdrawalType,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setWithdrawalType(e.text);
                        }
                    })}
                />
                {withdrawalType == "Etc" ? 
                <TextAreaItem
                    addClass=""
                    inputName=""
                    placeholder="Please let us know the reason for your withdrawal."
                    max={500}
                    maxChk={true}
                    value={withdrawalText}
                    func={(e)=>setWithdrawalText(e)}
                /> : ""}
            </MypageSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <ChkBox
                    addClass=""
                    checkedType={chkVal}
                    func={(e) => setChkVal(e.target.checked)}
                    label={"I have reviewed all the guidelines and agree to them."}
                />
                <BtnSubBox addClass="col2">
                    <BtnItem
                        addClass="line col2"
                        contents="Cancel"
                        disabled={false}
                        func={() => {navigate(-1)}}
                    />
                    <BtnItem
                        addClass="col2"
                        contents="Withdrawal"
                        disabled={btnChk}
                        func={() => {withdrawalStart()}}
                    />
                </BtnSubBox>
            </BtnBox>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Withdrawal;