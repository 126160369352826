import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { LoadingBox, Popup, SelPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { BtnBox, BtnItem, BtnSubBox } from "component/basic/btns";
import { ChkBox, InputItemBox, TextAreaItem } from "component/basic/formItems";
import { phoneChk } from "js/function";

const Collaboration = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;

    const [csType,setCsType] = useState("");
    const [companyName,setCompanyName] = useState("");
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [title,setTitle] = useState("");
    const [csText,setCsText] = useState("");
    const [chkVal,setChkVal] =  useState(false);

    const [btnChk,setBtnChk] = useState(true);

    const [phoneErr,setPhoneErr] =  useState(null);

    //협업 문의
    function csStart(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("type", csType);
        formData.append("company_name", companyName);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("title", title);
        formData.append("contents", csText);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: localStorage.getItem("token") ? "login" : null,
            url: "/order/collaboration/request",
            success: (data) => {
                setLoadingBoxOpen(false);
                setPopupData({
                    addClass:null,
                    title:"Your inquiry has been submitted.",
                    text:"We will inform you through your email or mobile.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{window.location.reload(true);navigate("/")},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            },
            err: (data) => {
                setLoadingBoxOpen(false);
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"Inquiry Submission Failed",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    //버튼 유효성
    useEffect(() => {
        if(csType !== "" && companyName !== "" && name !== "" && emailRegExp.test(email) && phoneChk(phone) && title !== "" && csText !== "" && chkVal){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [csType,companyName,name,email,phone,title,csText,chkVal]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Collaboration Inquiry"
                centerTitle={true}
            />
            <MypageSection addClass="bottomBtn">
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Type of Inquiry"
                    placeholder="Please select the type of inquiry."
                    value={csType}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Type of Inquiry",
                        addClass:null,
                        closeType:true,
                        items:["Advertisement Partnership", "Business Partnership", "Etc"],
                        val:["Advertisement Partnership", "Business Partnership", "Etc"],
                        sel:csType,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCsType(e.text);
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Company Name"
                    placeholder="Please provide the company name."
                    value={companyName}
                    max={255}
                    regexp={null}
                    func={(value)=>{setCompanyName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Contact Person"
                    placeholder=""
                    value={name}
                    max={255}
                    regexp={null}
                    func={(value)=>{setName(value)}}
                    caption="Please provide the contact person's name."
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Contact Person's Email Address"
                    placeholder="example@email.com"
                    value={email}
                    max={255}
                    regexp={null}
                    func={(value)=>{setEmail(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={phone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={phoneErr}
                    func={(value)=>{setPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Subject of Inquiry"
                    placeholder="Please enter the subject of inquiry."
                    value={title}
                    max={255}
                    regexp={null}
                    func={(value)=>{setTitle(value)}}
                />
                <TextAreaItem
                    addClass=""
                    inputName="Inquiry Details"
                    placeholder="Please provide as much detail as possible."
                    max={500}
                    maxChk={true}
                    value={csText}
                    func={(value)=>setCsText(value)}
                />
            </MypageSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <ChkBox
                    addClass=""
                    checkedType={chkVal}
                    func={(e) => setChkVal(e.target.checked)}
                    label={"[Required] Privacy Policy"}
                    chkFunc={(e) => props.rightOpenFunc(2,"Privacy Policy")}
                />
                <BtnSubBox addClass="col2">
                    <BtnItem
                        addClass=""
                        contents="Submit"
                        disabled={btnChk}
                        func={() => {csStart()}}
                    />
                </BtnSubBox>
            </BtnBox>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Collaboration;