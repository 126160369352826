import * as fatchSet from "../../api/api";
import { useEffect } from "react";

export default function KeySetting(props){
    //비회원 키 발급
    function keyAdd(){
        let formData = new FormData();
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/nonmember`,
            success: (data) => {
                localStorage.setItem("nonmemberKey",data?.data?.nonmember_key);
            },
            err: (data) => {
                
            }
        })
    }
    
    useEffect(()=>{
        if(!localStorage.getItem("nonmemberKey") && !localStorage.getItem("token")){
            keyAdd();
        }
    },[props.token]);


    return null;
}