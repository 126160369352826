import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";

const PwEdit = (props) => {
    const navigate = useNavigate();

    const [errPopupData,setErrPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [pw,setPw] = useState("");
    const [newPw,setNewPw] = useState("");
    const [newPwCom,setNewPwCom] = useState("");

    const [pwErr,setPwErr] = useState(null);
    const [newPwErr,setNewPwErr] = useState(null);
    const [newPwComErr,setNewPwComErr] = useState(null);

    const [btnChk,setBtnChk] = useState(true);

    //비밀번호 변경
    function pwCh(){
        let formData = new FormData();
        formData.append("password", pw);
        formData.append("new_password", newPw);
        formData.append("new_password_check", newPwCom);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/mypage/password/change",
            success: (data) => {
                setBottomMsgData({
                    addClass : "",
                    text : "Password has changed successfully.",
                    chk : bottomMsgData.chk + 1
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:"Passwords do not match.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //버튼 유효성
    useEffect(() => {
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(newPw !== newPwCom && newPw !== "" && newPwCom !== ""){
            setNewPwComErr("Passwords do not match.")
        }else{
            setNewPwComErr(null)
        }

        if(!pwType.test(pw) && pw !== ""){
            setPwErr("Please enter a combination of English letters, numbers, and special characters, ranging from 8 to 20 characters.")
        }else{
            setPwErr(null)
        }

        if(!pwType.test(newPw) && newPw !== ""){
            setNewPwErr("Please enter a combination of English letters, numbers, and special characters, ranging from 8 to 20 characters.")
        }else{
            setNewPwErr(null)
        }

        if(newPw == newPwCom && pwType.test(newPw) && pwType.test(pw)){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [pw,newPw,newPwCom]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Set Password"
                centerTitle={true}
            />
            <MypageSection addClass="bottomBtn">
                <InputItemBox
                    inputType="password"
                    addClass=""
                    inputName="Current Password"
                    placeholder="Current Login Password"
                    value={pw}
                    max={20}
                    regexp={null}
                    errMsg={pwErr}
                    func={(value)=>{setPw(value)}}
                />
                <InputItemBox
                    inputType="password"
                    addClass=""
                    inputName="New Password"
                    placeholder="********"
                    value={newPw}
                    max={20}
                    regexp={null}
                    errMsg={newPwErr}
                    func={(value)=>{setNewPw(value)}}
                    caption="8 to 20 characters containing a combination of English letters, numbers, and special characters"
                />
                <InputItemBox
                    inputType="password"
                    addClass=""
                    inputName="Confirm New Password"
                    placeholder="Re-enter New Password"
                    value={newPwCom}
                    max={20}
                    regexp={null}
                    errMsg={newPwComErr}
                    func={(value)=>{setNewPwCom(value)}}
                />
            </MypageSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents="Complete"
                    disabled={btnChk}
                    func={() => {pwCh()}}
                />
            </BtnBox>
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={errPopupData}
            />
        </PageSizing>
    );
};

export default PwEdit;