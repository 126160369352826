import { comFormat } from "js/function";
import CountInput from "./count";

//장바구니 항목
function CartListItem(data){
    let id = `chk_item_${Math.floor(Math.random() * 1000)}`;

    return (
        <div className={`cartListItem ${data.addClass ? data.addClass : ""}`}>
            <div className="cartList_chkBox">
                <input type="checkbox" value={data.val ? data.val : ""} onChange={(e) => data.chkFunc(e)} className="chk_itemChk" id={id} checked={data.checkedType ? data.checkedType : null} disabled={data.disabled ? data.disabled : false}/>
                <label htmlFor={id}></label>
            </div>
            <div className="cartListProductItem">
                <div className="cartListProductItem_infoBox">
                    <div className="cartListProductItem_info">
                        <div className="cartListProductItem_img bgImgItem" style={{backgroundImage:`url('${data.img}')`}}/>
                        <div className="cartListProductItem_nameBox">
                            <h1 className="cartListProductItem_name notranslate" dangerouslySetInnerHTML={{__html:data.name}}/>
                            {data.option_name ? <p className="cartListProductItem_option" dangerouslySetInnerHTML={{__html:`${data.option_name} / ${data.option_qty}EA`}}/> : ""}
                        </div>
                    </div>
                    <button type="button" className="btn_productDel" onClick={()=>{data.delFunc(data.id)}}><img src="/assets/images/basic/del.svg"/></button>
                </div>
                <div className="cartListProductItem_priceBox">
                    <p className="cartListProductItem_price" dangerouslySetInnerHTML={{__html:`Rp${comFormat(data.price)}`}}/>
                    <CountInput
                        val={data.countVal}
                        qty={data.qty}
                        optionQty={data.optionQty}
                        func={data.countFunc}
                        excessFunc={data.excessFunc}
                        excessFunc_max={data.excessFunc_max}
                    />
                </div>
            </div>
        </div>
    ); 
}

export {CartListItem};