import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PublicOnly = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(!localStorage.getItem("token") && window.location.pathname !== "/pageErr"){
            // navigate(0);
        }
    }, []);

    return (
        <div className="pageSizing">
            <PageErrBox
                icon={true}
                title="<i>We apologize for</i><br/><i>any inconvenience with</i><i class='notranslate'> BabyDNA </i><i>usage.</i>"
                text="The page you are looking for does not exist, or an error has occurred."
            />
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Go back to the homepage"}
                    disabled={false}
                    func={() => {navigate("/main")}}
                />
            </BtnBox>
        </div>
    );
};

export default PublicOnly;