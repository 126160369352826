import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup, SelPopup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AlignBtnItem } from "component/basic/btns";
import { BgSection } from "component/app/items";
import { OrderListItem } from "component/mypage/order";
import { getDateGap, setDateTime, zendeskOpen } from "js/function";

const OrderList = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);

    const [align,setAlign] =  useState("");
    const [alignText,setAlignText] =  useState("All");
    
    const orderApi = useGet({
        url:`/order/view?status=${align}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.orders;

    //장바구니 다시 담기
    function addCart(productId,optionId,qty){
        let formData = new FormData();
        formData.append("product_id[]", productId);
        formData.append("option_id[]", optionId);
        formData.append("qty[]", qty);

        if(!localStorage.getItem("token")){
            formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/product/cart",
            success: (data) => {
                setToastPopupData({
                    addClass:"",
                    title: "Added to Cart!",
                    basicTitle:null,
                    basicText:"Selected item has been added to your cart.",
                    closeType:true,
                    btn0:"View Cart",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc0:(e)=>{
                        navigate("/cart");
                    },
                    btnFunc1:()=>{},
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"Failed to add items to the cart.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //주문 취소
    function orderCanCel(id){
        let formData = new FormData();
        formData.append("order_product_id", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/order/purchase/cancel",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //반송 확인
    function orderReceipt(id,orderType){
        let formData = new FormData();
        if(orderType == "redelivery"){
            formData.append("id", id);
        }else{
            formData.append("order_product_id", id);
        }
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: orderType == "redelivery" ? "/order/redelivery/receive" : "/order/receive",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="headerPage gBg">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Order History"
                centerTitle={true}
            />
            <BgSection addClass="">
                <div className="orderList_alignBox">
                    <AlignBtnItem
                        addClass=""
                        contents={alignText}
                        img="/assets/images/basic/more_icon_b.svg"
                        func={() => {setSelPopupData({
                            title:"Filter",
                            addClass:null,
                            closeType:false,
                            items:["All","Payment Complete","Preparing for Shipment","In transit","Delivered","Payment Cancellation Requested","Payment Canceled","Exchange","Applied for Exchange","Waiting for Exchange Return","Exchange Return in Progress","Exchange Return Completed","Exchange in transit","Exchange Completed","Refund","Applied for Refund","Waiting for Refund Return","Refund Return in Progress","Refund in Progress","Refund Completed","Reshipment","Preparing for Reshipment","Reshipment in Progress","Reshipment Completed"],
                            val:["","1","2","3","4","50","5","notSel","6","61","62","63","64","65","notSel","7","71","72","74","75","notSel","8","9","10"],
                            sel:alignText,
                            closeFunc:(e) => {},
                            func:(e) => {
                                setAlign(e.val);
                                setAlignText(e.text);
                            }
                        })}}
                    />
                </div>
                {orderData && orderData.length > 0 ? 
                    <div className="orderList_alignList">
                        {orderData.map((item,i)=>(
                            <OrderListItem
                                key={i}
                                id={item.id}
                                transportNumber={item.transportNumber}
                                return_price={item.return_price}
                                order_number={item.type == "Order Kit" ? item.order_number : item.id}
                                date={item.created_at}
                                receiptDate={item.receipt_at}
                                statusType={item.type}
                                caption={
                                    item.is_cancel == 1 ? 
                                        item.cancel_status == 0 ?
                                            "Payment Cancellation Requested"
                                            : "Payment Canceled"
                                    :
                                    item.type == "Order Kit" ? 
                                    item.request_status || item.request_status == 0 ? 
                                        item.request_type == "Exchange" ? 
                                            item.request_status == 0 ? "Applied for Exchange" : item.request_status == 1 ? "Waiting for Exchange Return" : item.request_status == 2 ? "Exchange Return in Progress" : item.request_status == 3 ? "Exchange Return Completed" : item.request_status == 4 ? "Exchange in transit" : item.request_status == 5 ? "Exchange Completed" : null
                                            : item.request_status == 0 ? "Applied for Refund" : item.request_status == 1 ? "Waiting for Refund Return" : item.request_status == 2 ? "Refund Return in Progress" : item.request_status == 3 ? item.refund_status == 2 ? "Refund in Progress" : item.refund_status == 1 ? "Refund Completed" : null : null
                                    :
                                    item.status == 1 ? "Payment Complete" : item.status == 2 ? "Preparing for Shipment" : item.status == 3 ? "In transit" : item.status == 4 ? "Delivered" : null
                                    :
                                    item.status == 0 ? "Preparing for Reshipment" : item.status == 1 ? "Reshipment in Progress" : item.status == 2 ? "Reshipment Completed" : null
                                }
                                captionAddClass={
                                    item.is_cancel == 1 ? 
                                    "rColor"
                                    :
                                    item.type == "Order Kit" ? 
                                    item.request_status || item.request_status == 0 ? 
                                        item.request_type == "Exchange" ? 
                                            item.request_status == 0 ? "rColor" : item.request_status == 1 ? "rColor" : item.request_status == 2 ? "yColor" : item.request_status == 3 ? "gColor" : item.request_status == 4 ? "yColor" : item.request_status == 5 ? "gColor" : null
                                            : item.request_status == 0 ? "rColor" : item.request_status == 1 ? "rColor" : item.request_status == 2 ? "yColor" : item.request_status == 3 ? item.refund_status == 2 ? "yColor" : item.refund_status == 1 ? "gColor" : null : null
                                    :
                                    item.status == 1 ? "gColor" : item.status == 2 ? "yColor" : item.status == 3 ? "yColor" : item.status == 4 ? "gColor" : null
                                    :
                                    item.status == 0 ? "yColor" : item.status == 1 ? "yColor" : item.status == 2 ? "gColor" : null
                                }
                                language={props.language}
                                product={{
                                    thumb_image:item.thumb_image,
                                    product_name:item.product_name,
                                    product_name_id:item.product_name_id,
                                    option_name:item.option_name,
                                    qty:item.qty,
                                    total_price:item.total_price
                                }}
                                reasonTitle={
                                    item.refuse_type == "Exchange" ? 
                                        `Reason for Exchange Rejection`
                                        : `Reason for Refund Rejection`
                                }
                                reason={item.request_text||""}
                                status={item.status}
                                request_status={item.request_status}
                                request_type={item.request_type}
                                is_cancel={item.is_cancel}
                                cancel_status={item.cancel_status}
                                refund_status={item.refund_status}
                                func={()=>{
                                    if(item.is_cancel == 1){
                                        if(item.cancel_status == 1){
                                            //"결제 취소"
                                            addCart(item.product_id,item.option_id,item.qty);//장바구니 다시 담기
                                        }
                                    }else if(item.type == "Order Kit"){
                                        if(item.request_status || item.request_status == 0){
                                            if(item.request_type == "Exchange"){
                                                if(item.request_status == 1){
                                                    // "Waiting for Exchange Return"
                                                    navigate(`/mypage/order/return/add/exchange/${item.id}/${item.order_product_id}`);//반송 신청
                                                }else if(item.request_status == 5){
                                                    //교환 완료
                                                    navigate("/addBarcode");
                                                }
                                            }else{
                                                if(item.request_status == 1){
                                                    // "환불 반송 대기"
                                                    navigate(`/mypage/order/return/add/return/${item.id}/${item.order_product_id}`);//반송 신청
                                                }else if(item.request_status == 3){
                                                    if(item.refund_status == 2){
                                                        //환불 중
                                                        zendeskOpen();
                                                    }else if(item.refund_status == 1){
                                                        //환불 완료
                                                        //"Refund Completed"
                                                        addCart(item.product_id,item.option_id,item.qty);//장바구니 다시 담기
                                                    }
                                                }
                                            }
                                        }else{
                                            if(item.status == 1){
                                                //"결제완료"
                                                setPopupData({
                                                    addClass:null,
                                                    title:"Hold on! Do you want to cancel the entire order?",
                                                    text:"The refund link will be sent to your email. Please check the email you used to sign up. Email delivery may take at least 3 days. Check your spam folder if not received.",
                                                    closeType:true,
                                                    closeFunc:(e) => {},
                                                    btnFunc0:(e)=>{},
                                                    btn0:"Close",
                                                    btn0Type:"line",
                                                    btn1:"Proceed",
                                                    btn1Type:"",
                                                    btnFunc1:()=>{
                                                        orderCanCel(item.order_product_id);
                                                    }
                                                });
                                            }else if(item.status == 2){
                                                //"배송 준비중"
                                                zendeskOpen();
                                            }else if(item.status == 3){
                                                //"배송중"
                                                zendeskOpen();
                                            }else if(item.status == 4){
                                                //"배송완료"
                                                if(item.request_text){
                                                    //교환,환불 반려
                                                    zendeskOpen();
                                                }else if(getDateGap(item.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") > 7){
                                                    //교환,환불 불가
                                                    navigate("/addBarcode");//바코드 등록
                                                }else{
                                                    //교환,환불 가능
                                                    navigate(`/mypage/order/cs/exchange/${item.id}/${item.order_product_id}`);//교환 신청
                                                }
                                            }
                                        }
                                    }else{
                                        
                                    }
                                }}
                                func2={()=>{
                                    if(item.is_cancel !== 1 && (!item.request_status && item.request_status !== 0)){
                                        if(item.status == 4){
                                            //"배송완료"
                                            if(item.request_text){
                                                //교환,환불 반려
                                                navigate("/addBarcode");//바코드 등록
                                            }else if(getDateGap(item.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7){
                                                //교환,환불 가능
                                                navigate(`/mypage/order/cs/return/${item.id}/${item.order_product_id}`);//환불 신청
                                            }
                                        }
                                    }
                                }}
                                func3={()=>{
                                    if(item.is_cancel !== 1 && (!item.request_status && item.request_status !== 0) && item.status == 4 && !item.request_text && getDateGap(item.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7){
                                        navigate("/addBarcode");
                                    }
                                }}
                            />
                        ))}
                    </div>
                :
                    <div className="orderList_notList">
                        <img src="/assets/images/icon/notOrder.svg"/>
                        <i>No order history.</i>
                    </div>
                }
            </BgSection>
            <ToastPopup data={toastPopupData} closeType={true}>
                <ToastBasicContents
                    title={null}
                    text={"Selected item has been added to your cart."}
                />
            </ToastPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default OrderList;